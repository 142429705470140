import { CaretRightOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Divider, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { StyledLink } from 'src/Modules/Common/Components/LinkWithParam'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import { SuspenseAndBoundary } from 'src/Modules/Utilities/Components/SuspenseAndBoundary'

type MyTreeNodeProps = {
  title: string
  options: JSX.Element[]
  onOpen?: () => void
  hasChildren: boolean
  getChildren: () => React.ReactNode
  to?: string
  defaultOpen?: boolean
}

/** Custom tree node */
export function MyTreeNode(props: MyTreeNodeProps) {
  const opened = useRef(false)
  const [open, setOpen] = useState<boolean>(false)

  const { onOpen, defaultOpen } = props
  useEffect(() => {
    if (defaultOpen) {
      setOpen(true)
      if (onOpen && !opened.current) {
        opened.current = true
        onOpen()
      }
    }
  }, [defaultOpen, onOpen, setOpen])

  const children = open ? props.getChildren() : null

  return (
    <div>
      <LabelWrapper>
        <StyledCaret
          onClick={() => {
            setOpen(!open)
            if (props.onOpen && !opened.current) {
              opened.current = true
              props.onOpen()
            }
          }}
          transform={open ? 'rotate(90deg)' : 'none'}
          visibility={props.hasChildren ? 'visible' : 'hidden'}
        />
        <div>
          <span>
            {props.to ? (
              <StyledLink to={props.to}>{props.title}</StyledLink>
            ) : (
              <Typography.Text>{props.title}</Typography.Text>
            )}
            <OptionsWrapper>{props.options}</OptionsWrapper>
          </span>
        </div>
      </LabelWrapper>
      {open &&
        (children ? (
          <SuspenseAndBoundary>
            <ChildrenWrapper>
              <StyledDivider type='vertical' />
              <div style={{ display: 'grid' }}>{children}</div>
            </ChildrenWrapper>
          </SuspenseAndBoundary>
        ) : (
          <CenteredCustomSpinner />
        ))}
    </div>
  )
}

// Styling
const LabelWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: start;
  width: 100%;

  :hover {
    color: white;
    background-color: lightgray;

    //Set copy and delete buttons visible.
    .anticon-file-add,
    .anticon-copy,
    .anticon-reload,
    .anticon-delete {
      visibility: visible;
    }
  }
`

const StyledCaret = styled(CaretRightOutlined)<{
  transform: 'rotate(90deg)' | 'none'
  visibility: 'visible' | 'hidden'
}>`
  transform: ${(props) => props.transform};
  visibility: ${(props) => props.visibility};
  transition: transform 150ms ease-out;
  align-self: center;
`

const OptionsWrapper = styled.span`
  float: right;
  visibility: hidden;
`

const ChildrenWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: start;
`

const StyledDivider = styled(Divider)`
  height: 100%;
  border-left-color: gray !important;
`
