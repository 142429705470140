import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate
} from '@azure/msal-react'
import styled from '@emotion/styled'
import 'antd/dist/antd.dark.min.css'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import NavMenu from 'src/Modules/Common/Components/NavMenu'
import NotFound from 'src/Modules/Common/Components/NotFound'
import { ParamContent } from 'src/Modules/Common/Components/ParamContent'
import { FullHeightDiv } from 'src/Modules/Common/Containers/FullHeightDiv'
import NeedLogin from 'src/Modules/Common/Containers/NeedLogin'
import { SchemaCards } from 'src/Modules/DevPage/Components/SchemaCards'
import { SchemaCreatorWrapper } from 'src/Modules/DevPage/Containers/SchemaCreatorWrapper'
import SidebarSearch from 'src/Modules/Home/Components/SidebarSearch'
import { TreeWithSelect } from 'src/Modules/Home/Components/Tree/TreeWithSelect'
import Home from 'src/Modules/Home/Containers/Home'
import { queryClient } from 'src/Modules/Home/Hooks/QueryClient'
import { MsalInstance } from 'src/Modules/Utilities/Authorization/MsalInstance'
import { SuspenseAndBoundary } from 'src/Modules/Utilities/Components/SuspenseAndBoundary'

/** Main application container */
export function App() {
  return (
    <MsalProvider instance={MsalInstance}>
      <BrowserRouter key={1}>
        <AppContainer>
          <QueryClientProvider client={queryClient}>
            <ContentDiv>
              <AuthenticatedTemplate>
                <MainDiv>
                  <NavMenu />
                  <SuspenseAndBoundary>
                    <Routes>
                      <Route
                        path='/'
                        element={<Home sidebar={<TreeWithSelect />} />}
                      />
                      <Route
                        path='/home/:type/:id'
                        element={<Home sidebar={<TreeWithSelect />} />}
                      />
                      <Route
                        path='/home/:type/:id/:action'
                        element={<Home sidebar={<TreeWithSelect />} />}
                      />
                      <Route
                        path='/search'
                        element={<Home sidebar={<SidebarSearch />} />}
                      />
                      <Route
                        path='/search/:type/:id'
                        element={<Home sidebar={<SidebarSearch />} />}
                      />
                      <Route
                        path='/search/:type/:id/:action'
                        element={<Home sidebar={<SidebarSearch />} />}
                      />
                      <Route
                        path='/development/schemas'
                        element={
                          <FullHeightDiv>
                            <SchemaCards />
                          </FullHeightDiv>
                        }
                      />
                      <Route
                        path='/development/:type/:id'
                        element={
                          <FullHeightDiv>
                            <ParamContent />
                          </FullHeightDiv>
                        }
                      />
                      <Route
                        path='/development/:type/:id/:action'
                        element={
                          <FullHeightDiv>
                            <ParamContent />
                          </FullHeightDiv>
                        }
                      />
                      <Route
                        path='/development/create'
                        element={
                          <FullHeightDiv>
                            <SchemaCreatorWrapper />
                          </FullHeightDiv>
                        }
                      />
                      <Route path='*' element={<NotFound />} />
                    </Routes>
                  </SuspenseAndBoundary>
                </MainDiv>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <NeedLogin />
              </UnauthenticatedTemplate>
            </ContentDiv>
          </QueryClientProvider>
        </AppContainer>
      </BrowserRouter>
    </MsalProvider>
  )
}

// Styling
const AppContainer = styled.div`
  height: 100vh;
`

const ContentDiv = styled.div`
  height: 100%;
`

const MainDiv = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`
