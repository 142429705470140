import {
  EditOutlined,
  EyeOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import styled from '@emotion/styled'
import { FieldTemplateProps } from '@rjsf/core'
import { Popover, Typography } from 'antd'
import { useState } from 'react'

import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'

const { Text, Link } = Typography

/**
 * Custom syspass field template for RSJF
 * @param props RSJF Field Template Props
 * @returns Field component
 */
export default function SyspassField(props: FieldTemplateProps) {
  const value = props.formData as string | undefined

  // Start with edit mode if the link has never been set before, otherwise start with link mode (when viewing existing document)
  const [edit, setEdit] = useState(value === undefined)

  return (
    <div className={props.classNames}>
      <Popover
        title='How To Get Syspass Link'
        placement='topLeft'
        content={
          <>
            <StyledText>
              1. Go to account details for an account (Eye icon)
            </StyledText>
            <StyledText>
              2. Scroll to direct link and click (Paperclip icon)
            </StyledText>
            <StyledText>
              3. Copy URL from address bar and paste below
            </StyledText>
          </>
        }
      >
        <StyledQuestion />
        <label htmlFor={props.id}>
          {props.label}
          {props.required ? '*' : null}
        </label>
      </Popover>
      {edit ? (
        <StyledEyeOutlined
          onClick={() => {
            if (props.errors.props.errors === undefined) setEdit(false)
            else
              NotificationError(
                'Invalid Link',
                'Save not possible due to invalid syspass link.'
              )
          }}
        />
      ) : (
        <StyledEditOutlined onClick={() => setEdit(true)} />
      )}
      {props.description}
      {edit ? (
        // Show default string input for RSJF
        props.children
      ) : (
        <StyledLink target='_blank' href={value}>
          {value}
        </StyledLink>
      )}
      {props.errors}
      {props.help}
    </div>
  )
}

const StyledQuestion = styled(QuestionCircleOutlined)`
  margin-right: 5px;
`

const StyledText = styled(Text)`
  display: block;
`

const StyledLink = styled(Link)`
  display: block;
`
const StyledEditOutlined = styled(EditOutlined)`
  margin-left: 10px;
  color: gray;
`

const StyledEyeOutlined = styled(EyeOutlined)`
  margin-left: 10px;
  color: gray;
`
