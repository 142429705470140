import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery } from 'react-relay'

import { QueriesSimInformationQuery } from 'src/Modules/Graphql/OliviaSimCard/__generated__/QueriesSimInformationQuery.graphql'
import { OliviaRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'

/**
 * Gets sim information for a given SIM card given document Id.
 * @param documentId Document id to get the information for.
 */
export function GetSimInformation(documentId: number) {
  const query = graphql`
    query QueriesSimInformationQuery($documentId: ID!) {
      getSimInformationFromId(documentId: $documentId) {
        iccid
        imsi
        simState
        simPlan
        deviceName
        group
        usageSms
        usageData
        usageVoice
        usageSmsPmt
        msisdn
      }
    }
  `

  return fetchQuery<QueriesSimInformationQuery>(
    OliviaRelayEnvironment,
    query,
    { documentId: documentId.toString() },
    { fetchPolicy: 'store-or-network' }
  ).toPromise()
}
