import { Input, Modal } from 'antd'
import React, { useState } from 'react'

type SingleInputModalProps = {
  title: string
  visible: boolean
  onOk: (value: string | undefined) => void
  onCancel: () => void
  initialInput?: string
}

/** Modal with a single input field. */
export function SingleInputModal(props: SingleInputModalProps) {
  const [value, setValue] = useState<string | undefined>(props.initialInput)

  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onOk={() => props.onOk(value)}
      onCancel={() => props.onCancel()}
    >
      <Input
        onChange={(e) =>
          setValue(e.currentTarget.value ? e.currentTarget.value : undefined)
        }
        value={value}
      />
    </Modal>
  )
}
