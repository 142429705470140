import styled from '@emotion/styled'
import { Divider } from 'antd'

import { ParamContent } from 'src/Modules/Common/Components/ParamContent'
import { SuspenseAndBoundary } from 'src/Modules/Utilities/Components/SuspenseAndBoundary'

/** Home page container */
export default function Home(props: { sidebar: JSX.Element }) {
  return (
    <HomeWrapper>
      <SuspenseAndBoundary>{props.sidebar}</SuspenseAndBoundary>
      <StyledDivider type='vertical' />
      <SuspenseAndBoundary>
        <ContentWrapper>
          <ParamContent />
        </ContentWrapper>
      </SuspenseAndBoundary>
    </HomeWrapper>
  )
}

// Styling
const HomeWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto 4fr;
  overflow-y: hidden;
`

const StyledDivider = styled(Divider)`
  height: 100%;
  margin: 0px;
`

const ContentWrapper = styled.div`
  overflow-y: auto;
`
