import React from 'react'

import { RemoveDocument } from 'src/Modules/Graphql/DocumentManager/Mutations'
import { DeleteButton } from 'src/Modules/Home/Components/NodeOptionButtons/DeleteButton/DeleteButton'
import {
  invalidateDocuments,
  useDocument
} from 'src/Modules/Home/Hooks/Document'
import { invalidateSchemas } from 'src/Modules/Home/Hooks/Schema'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/**
 * Delete document button.
 * @param props Prop with document id.
 * @returns A component to remove a document.
 */
export function DeleteDocumentButton(props: {
  documentId: number
  className?: string
}) {
  const { refetch } = useDocument(props.documentId, {
    enabled: false
  })

  return (
    <>
      <DeleteButton
        buttonProps={{
          className: props.className,
          onClick: (e) => e.stopPropagation()
        }}
        resourceName={props.documentId.toString()}
        onConfirmDelete={() => RemoveThisDocument()}
      />
    </>
  )

  /** Remove the document belonging to this node */
  async function RemoveThisDocument() {
    const { data: document } = await refetch()
    try {
      if (document == null) throw new Error('Document not found')

      await RemoveDocument(props.documentId)

      NotificationSuccess('Deleted', 'Document successfully deleted')

      await invalidateSchemas([
        document.schemaId,
        ...document.referencingSchemas
      ])
      await invalidateDocuments([
        props.documentId,
        ...document.referencingDocuments
      ])
    } catch (e: any) {
      NotificationError('Error on Document Delete', e.message, e)
    }
  }
}
