import styled from '@emotion/styled'
import { Input } from 'antd'
import JsonPath from 'jsonpath'
import React from 'react'

import {
  GetDocumentValue,
  GetSchemaValue
} from 'src/Modules/Graphql/MetaDataManager/Queries'
import { DataUrlToBlob } from 'src/Modules/RJSF/Components/FileWidget'
import { DocumentName } from 'src/Modules/Utilities/DocumentName'

/**
 * Gets the auto name kvp value from the metadata manager if it exists
 * @param schemaId Id of the schema for which to retrieve the autoname
 * @returns The autoname string if it exists
 */
export async function GetAutoName(
  schemaId: number
): Promise<string | undefined> {
  return (await GetSchemaValue(schemaId, 'autoName'))?.value
}

/**
 * Get the input field values from the name.
 * @param name The name to get the input fields from.
 * @returns The input field values.
 */
export function AutonameInputValues(name: string) {
  return [...name.matchAll(/;(.*?);/g)].map((match) => match[1])
}

/**
 * Creates input fields for autoName, gets values from name if already present.
 * @param autoName Auto name query
 * @param onInputChange Callback to set state containing input values
 * @param inputs Optional current inputs
 * @returns Multiple input fields
 */
export function AutoNameInputs(props: {
  autoName: string
  onInputChange: (inputs: string[]) => void
  inputs?: string[]
}) {
  const inputPlaceholders = [
    ...props.autoName.matchAll(/`input\((.*?)\)`/g)
  ].map((match) => match[1])

  return (
    <>
      {inputPlaceholders.map((label, index) => (
        <InputWrapper key={index}>
          {label}:
          <Input
            style={{ marginLeft: 5 }}
            onChange={(e) => {
              const newInputs = props.inputs ? [...props.inputs] : []
              newInputs[index] = e.currentTarget.value ?? ''
              props.onInputChange(newInputs)
            }}
            defaultValue={props.inputs ? props.inputs[index] : undefined}
          />
        </InputWrapper>
      ))}
    </>
  )
}

/**
 * Creates the automatic name from the autoname query and form data json
 * @param autoNameQuery Autoname pattern
 * @param formData Formdata of the document
 * @returns Automatically generated name
 */
export async function GenerateAutoName(
  autoNameQuery: string,
  formData: any,
  inputArray: string[]
): Promise<DocumentName> {
  // Replace all input fields with the corresponding item in the input array
  let iterator = 0
  const autoNameWithInput = autoNameQuery.replace(/`input\(.*?\)`/g, () => {
    const name: string | undefined = inputArray[iterator++]
    return `;${name ? ValidateName(name) : ''};`
  })

  // Retrieve all fields from the object itself async.
  const data = await Promise.all(
    [...autoNameWithInput.matchAll(/`(file|schema|document)?(\$.*?)`/g)].map(
      (match) =>
        GetNameFromObject(JsonPath.query(formData, match[2])[0], match[1])
    )
  )

  // Insert these new values
  const autoNameWithContent = autoNameWithInput.replace(
    /`(file|schema|document)?(\$.*?)`/g,
    () => data.shift()!
  )

  return new DocumentName(autoNameWithContent)
}

/**
 * Get the proper name from the given object
 * @param object The object to extract the name from
 */
async function GetNameFromObject(object: any, type?: string): Promise<string> {
  if (typeof object === 'number' && type)
    switch (type) {
      case 'schema':
        return (await GetSchemaValue(object, 'name'))!.value
      case 'document':
        return (await GetDocumentValue(object, 'name'))!.value
      default:
        throw new Error(`Unknown type ${type}`)
    }

  if (typeof object === 'string' && type === 'file')
    return DataUrlToBlob(object).name

  return object.toString()
}

/**
 * Escapes the ; in a string
 * @param name Name to escape.
 * @returns Escaped name.
 */
export function ValidateName(name: string) {
  if (name.includes(';')) throw new Error('Name cannot contain ;')
  return name
}

// Styling
const InputWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: 10px 10px 5px 10px;
`
