import { GetSchemasWithReferencesAndDocuments } from 'src/Modules/Graphql/DocumentManager/Queries'
import { QueriesSchemasWithReferencesAndDocumentsQuery$data } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesSchemasWithReferencesAndDocumentsQuery.graphql'
import { CreateDataloader } from 'src/Modules/Home/Hooks/Dataloader'
import { CreateDataloadedQuery } from 'src/Modules/Home/Hooks/QueryClient'

const SchemaDataloader = CreateDataloader(
  GetSchemasWithReferencesAndDocuments,
  (schema) => [schema._id, SchemaToJson(schema)]
)

/**
 * Converts a schema from the graphql query to a schema that can be used in the frontend
 * @param schema The schema to convert
 * @returns The converted schema
 */
export function SchemaToJson(
  schema: QueriesSchemasWithReferencesAndDocumentsQuery$data['schemas'][0]
) {
  return {
    id: schema._id,
    versionId: schema.versionAt?._id,
    documents: schema.documents.map((document) => document._id),
    referencingSchemas:
      schema.versionAt?.referencingSchemas.map((schema) => schema._id) ?? [],
    referencedBySchemas: schema.referencedBySchemaVersions.map(
      (schemaVersion) => schemaVersion.schema._id
    ),
    referencedByDocuments: schema.referencedByDocumentVersions.map(
      (documentVersion) => documentVersion.document._id
    )
  }
}

const { useSingle, useMultiple, fetchMultiple, invalidateMultiple } =
  CreateDataloadedQuery('schema', SchemaDataloader)

export const useSchema = useSingle
export const useSchemas = useMultiple
export const fetchSchemas = fetchMultiple
export const invalidateSchemas = invalidateMultiple
