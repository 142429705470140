import { ReloadOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

/**
 * Button used to refresh a tree node
 * @param props Onclick function and classname
 * @returns Clickable refresh icon
 */
export function RefreshButton(props: {
  onClick?: () => void
  className?: string
}) {
  return (
    <Tooltip title='Refresh' className={props.className}>
      <ReloadOutlined onClick={props.onClick} />
    </Tooltip>
  )
}
