import styled from '@emotion/styled'
import { Typography } from 'antd'
import React from 'react'

/**
 * Editor title wrapper
 * @param props Disabled + children
 * @returns Editor title
 */
export function EditorTitle(props: {
  disabled: boolean
  children: string
  onMouseEnter?: React.MouseEventHandler<HTMLSpanElement>
  onMouseLeave?: React.MouseEventHandler<HTMLSpanElement>
}) {
  return (
    <span onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <EditorTitleStyle level={2} disabled={props.disabled}>
        {props.children}
      </EditorTitleStyle>
    </span>
  )
}

const EditorTitleStyle = styled(Typography.Title)`
  margin: 5px 0px 5px 0px !important;
  display: inline-block;
`
