import styled from '@emotion/styled'
import { Tabs } from 'antd'
import React, { ReactNode } from 'react'

import { SuspenseAndBoundary } from 'src/Modules/Utilities/Components/SuspenseAndBoundary'

export const TabsWrapper = styled(Tabs)`
  height: 100%;
  overflow: hidden;
  .ant-tabs-nav {
    padding-left: 10px;
  }
`

/**
 * wrap tab content in a suspense spinner.
 * @param children tab content.
 * @returns a tab wrapper with a suspense spinner.
 */
export function TabPaneWrapper({
  children,
  ...tabPaneProps
}: {
  children: ReactNode
} & React.ComponentProps<typeof Tabs.TabPane>) {
  return (
    <StyledTabPaneWrapper {...tabPaneProps}>
      <SuspenseAndBoundary>{children}</SuspenseAndBoundary>
    </StyledTabPaneWrapper>
  )
}

const StyledTabPaneWrapper = styled(Tabs.TabPane)`
  height: 100%;
  overflow: hidden;
`
