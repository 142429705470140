import styled from '@emotion/styled'
import React, { useState } from 'react'

import { UseNavigateWithParam } from 'src/Modules/Common/Hooks/UseNavigateWithParam'
import { AddSchema } from 'src/Modules/Graphql/DocumentManager/Mutations'
import { SetSchemaKVP } from 'src/Modules/Graphql/MetaDataManager/Mutations'
import TopContentWrapper from 'src/Modules/Home/Components/ContentWrappers/TopContentWrapper'
import CodeEditor from 'src/Modules/Home/Components/Editors/CodeEditor'
import { NameInput } from 'src/Modules/Home/Components/NameInput'
import {
  TabPaneWrapper,
  TabsWrapper
} from 'src/Modules/Home/Components/TabsWrapper'
import { StyledFormOutlined } from 'src/Modules/Home/Components/Title/TitleIcons/StyledFomOutlined'
import { CreatorTitle } from 'src/Modules/Home/Components/Title/TitleText/CreatorTitle'
import { invalidateSchemas } from 'src/Modules/Home/Hooks/Schema'
import { invalidateSchemaNames } from 'src/Modules/Home/Hooks/SchemaName'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/** Container that implements a JsonEditor and uploads the schema on submit */
export function SchemaCreator(props: {
  onSucceed: (schemaId: number) => void
}) {
  const [name, setName] = useState<string | undefined>()
  const navigate = UseNavigateWithParam()

  return (
    <TopContentWrapper
      top={
        <div>
          <StyledFormOutlined />
          <CreatorTitle level={2}>New schema</CreatorTitle>
        </div>
      }
    >
      <ContentFreeWrapper>
        <NameInput value={name} onNameChange={(name) => setName(name)} />
        <TabsWrapper>
          <TabPaneWrapper tab='Json Editor' key='Json Editor'>
            <CodeEditor
              onSubmit={(json) => UploadSchema(json)}
              submitText='Submit'
            />
          </TabPaneWrapper>
        </TabsWrapper>
      </ContentFreeWrapper>
    </TopContentWrapper>
  )

  /**
   * Adds a new schema to the document manager and subsequently add a name using the metadatamanager
   * @param json the json to upload to the document manager
   */
  async function UploadSchema(json: any) {
    let newSchema
    try {
      // First add the schema
      newSchema = await AddSchema(json)
    } catch (e: any) {
      NotificationError('GraphQL error', e.message, e)
      return
    }

    await invalidateSchemas([
      newSchema._id,
      ...(newSchema.versionAt?.referencingSchemas.map((schema) => schema._id) ??
        [])
    ])

    if (name)
      try {
        // If that succeeds, add the name
        const newSchemaName = await SetSchemaKVP(newSchema._id, 'name', name)
        setName(newSchemaName.value)
        await invalidateSchemaNames([newSchema._id])
      } catch (e: any) {
        NotificationError('GraphQL error', e.message, e)

        navigate(`/development/schema/${newSchema._id}`)
        return
      }

    NotificationSuccess('Success', 'Successfully created schema.')

    // On succesful creation
    props.onSucceed(newSchema._id)
  }
}

// Styles
const ContentFreeWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`
