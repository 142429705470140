import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import React from 'react'
import { JSXElementConstructor, ReactElement } from 'react'

/**
 * A dropdown button for card title.
 * @param props A dropdown with content
 * @returns A dropdown button
 */
export function TitleDropdown(props: {
  children: ReactElement<any, string | JSXElementConstructor<any>>
}) {
  return (
    <Dropdown overlay={props.children}>
      <Button type='text' icon={<MoreOutlined />} />
    </Dropdown>
  )
}
