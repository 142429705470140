import { GetDocumentsWithReferences } from 'src/Modules/Graphql/DocumentManager/Queries'
import { QueriesDocumentsWithReferences2Query$data } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentsWithReferences2Query.graphql'
import { CreateDataloader } from 'src/Modules/Home/Hooks/Dataloader'
import { CreateDataloadedQuery } from 'src/Modules/Home/Hooks/QueryClient'

const DocumentDataloader = CreateDataloader(
  GetDocumentsWithReferences,
  (document) => [document._id, DocumentToJson(document)]
)

/**
 * Converts a document from the graphql query to a document that can be used in the frontend
 * @param document The document to convert
 * @returns The converted document
 */
export function DocumentToJson(
  document: QueriesDocumentsWithReferences2Query$data['documents'][0]
) {
  return {
    id: document._id,
    versionId: document.versionAt?._id,
    schemaId: document.schema._id,
    referencedByDocuments: document.referencedByDocumentVersions.map(
      (documentVersion) => documentVersion.document._id
    ),
    referencingSchemas:
      document.versionAt?.referencingSchemas.map((schema) => schema._id) ?? [],
    referencingDocuments:
      document.versionAt?.referencingDocuments.map(
        (document) => document._id
      ) ?? []
  }
}

const { useSingle, useMultiple, fetchMultiple, invalidateMultiple } =
  CreateDataloadedQuery('document', DocumentDataloader)

export const useDocument = useSingle
export const useDocuments = useMultiple
export const fetchDocuments = fetchMultiple
export const invalidateDocuments = invalidateMultiple
