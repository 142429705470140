export const Authority =
  'https://login.microsoftonline.com/25f2f5dc-0e4d-4c27-b5b4-ff4b5bf90d00'
export const TenantId = '25f2f5dc-0e4d-4c27-b5b4-ff4b5bf90d00'
export const InterfaceClientId = '01110615-53c4-4bf5-8eac-43290a883de6'

export const DocumentManagerScope = [
  'api://123ffe5a-a350-4e25-8f93-9f120f922881/default'
]
export const MetadataManagerScope = [
  'api://98b8a4ec-6673-4c98-a67b-7278476fffcf/default'
]
export const LoggingDatabaseScope = [
  'api://3a567d3d-e41f-48b4-b17b-31d6e60defdc/default'
]
export const EviewScope = ['api://9d3cfbd2-8a2b-48a7-862c-9177f21c5fdc/default']
export const OliviaScope = [
  'api://b673884f-8c56-490f-b0fd-1cbd8d13d323/default'
]

export const LoginScope = {
  scopes: ['openid']
}

export const IntegrationsKeyName = 'integrations'

export const TreeSchemaId = 120

export const treeParam = 'tree'
export const searchParam = 'query'
export const parentDocumentIdParam = 'parentDocumentId'
