import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'

import { MutationsActivateSimCardMutation } from 'src/Modules/Graphql/OliviaSimCard/__generated__/MutationsActivateSimCardMutation.graphql'
import { MutationsDeactivateSimCardMutation } from 'src/Modules/Graphql/OliviaSimCard/__generated__/MutationsDeactivateSimCardMutation.graphql'
import { OliviaRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'

/**
 * Activate the sim card
 * @param documentId The documentid of the sim to activate.
 * @returns a promise
 */
export async function ActivateSimCard(documentId: number) {
  const mutation = graphql`
    mutation MutationsActivateSimCardMutation($documentId: ID!) {
      activateSimCard(documentId: $documentId)
    }
  `

  return new Promise((resolve, reject) => {
    commitMutation<MutationsActivateSimCardMutation>(OliviaRelayEnvironment, {
      mutation: mutation,
      variables: { documentId: documentId.toString() },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.activateSimCard)
      }
    })
  })
}

/**
 * Deactivate the sim card
 * @param documentId The documentid of the sim to deactivate.
 * @returns a promise
 */
export async function DeactivateSimCard(documentId: number) {
  const mutation = graphql`
    mutation MutationsDeactivateSimCardMutation($documentId: ID!) {
      deactivateSimCard(documentId: $documentId)
    }
  `

  return new Promise((resolve, reject) => {
    commitMutation<MutationsDeactivateSimCardMutation>(OliviaRelayEnvironment, {
      mutation: mutation,
      variables: { documentId: documentId.toString() },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.deactivateSimCard)
      }
    })
  })
}
