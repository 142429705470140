/* eslint-disable react/jsx-no-undef */
import { Button, Table } from 'antd'
import { useMemo } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'

import { GetPaginatedMacAdresses } from 'src/Modules/Graphql/LoggingDatabase/Queries'
import { QueriesPaginatedMacAddressesQuery$data } from 'src/Modules/Graphql/LoggingDatabase/__generated__/QueriesPaginatedMacAddressesQuery.graphql'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import DateColumn from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/DateColumn'
import DocumentIdColumn from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/DocumentIdColumn'
import UseOneOrMany from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/UseIds'
import CustomSpinner from 'src/Modules/Utilities/Components/CustomSpinner'

type macAddress = NonNullable<
  NonNullable<
    QueriesPaginatedMacAddressesQuery$data['paginatedMacAdresses']
  >['items']
>[0]

/**
 * A component to display mac adddresses of devices.
 * @param props Props to determine what mac addresses should be displayed.
 * @returns A component to display mac addresses.
 */
export default function MacAddressPage(props: {
  documentIds: number | number[]
}) {
  const documentIds = UseOneOrMany(props.documentIds)

  type PageInfo =
    | {
        endCursor: string | null
        hasNextPage: boolean
      }
    | undefined

  const queryClient = useQueryClient()

  /**
   * Function to fetch the next mac addresses
   * @param param page params passed by infinite query - in our case the end cursor of the last page
   * @returns Page info containing the end cursor/has next page and the mac addresses
   */
  async function fetchMacAddresses({
    pageParam = undefined
  }): Promise<[PageInfo, readonly macAddress[]]> {
    const result = (await GetPaginatedMacAdresses(documentIds, 20, pageParam))
      ?.paginatedMacAdresses
    return [result?.pageInfo, result?.items ?? []]
  }

  const { data, fetchNextPage, isFetching, status, hasNextPage } =
    useInfiniteQuery(['macAddresses', documentIds], fetchMacAddresses, {
      getNextPageParam: (lastPage, _) =>
        lastPage[0]?.hasNextPage ? lastPage[0].endCursor : undefined,
      staleTime: Infinity
    })

  const macAddresses = useMemo(
    () => data?.pages.flatMap(([, page]) => page),
    [data]
  )

  const columns = [
    ...(typeof props.documentIds === 'object'
      ? [DocumentIdColumn(props.documentIds)]
      : []),
    {
      title: 'DateTime',
      dataIndex: 'dateTime',
      key: 'datetime',
      ...DateColumn<macAddress>()
    },
    {
      title: 'MacAddress',
      dataIndex: 'macAddress',
      key: 'macAddress'
    }
  ]

  return (
    <TabContentWrapper
      bottom={
        <>
          <Button
            type='primary'
            onClick={async () => await fetchNextPage()}
            loading={isFetching}
            disabled={!hasNextPage}
          >
            Load More
          </Button>

          <Button
            onClick={() => {
              queryClient.removeQueries(['macAddresses', documentIds])
              fetchNextPage()
            }}
            loading={isFetching}
          >
            Refresh
          </Button>
        </>
      }
    >
      <Table
        loading={{
          spinning: status === 'loading',
          indicator: <CustomSpinner />
        }}
        dataSource={
          macAddresses?.map((address) => ({
            ...address,
            key: address!._id
          })) ?? []
        }
        columns={columns}
        rowKey='_id'
        pagination={false}
      />
    </TabContentWrapper>
  )
}
