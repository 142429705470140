import styled from '@emotion/styled'
import { Badge, BadgeProps, Descriptions, Typography } from 'antd'

import { GetSimInformation } from 'src/Modules/Graphql/OliviaSimCard/Queries'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import { UseCustomQuery } from 'src/Modules/Home/Hooks/QueryClient'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'

/**
 * Component that displays SIM information about a given SIM card document.
 * @param props SIM Page props.
 * @returns SIM page component.
 */
export default function SimPage(props: { documentId: number }) {
  const { data } = UseCustomQuery(
    ['simInformation', props.documentId],
    () => GetSimInformation(props.documentId),
    {
      suspense: true
    }
  )
  const info = data?.getSimInformationFromId

  return (
    <TabContentWrapper>
      {info ? (
        <Descriptions bordered>
          <Descriptions.Item span={3} label='Iccid'>
            {info.iccid}
          </Descriptions.Item>
          <Descriptions.Item span={3} label='Imsi'>
            {info.imsi}
          </Descriptions.Item>
          <Descriptions.Item span={3} label='Phone Number'>
            {info.msisdn ?? (
              <Typography.Text type='secondary'>Unknown</Typography.Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} label='Status'>
            {info.simState}
            <StyledBadge
              status={SimStateToStatus[info.simState.toUpperCase()]}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} label='Device Name'>
            {info.deviceName}
          </Descriptions.Item>
          <Descriptions.Item span={3} label='SMS Sent (This month)'>
            {info.usageSms}
          </Descriptions.Item>
          <Descriptions.Item span={3} label='KB Data Used (This month)'>
            {info.usageData / 1000}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <CenteredCustomSpinner />
      )}
    </TabContentWrapper>
  )
}

const StyledBadge = styled(Badge)`
  margin-left: 10px;
`

const SimStateToStatus: Record<string, BadgeProps['status']> = {
  ACTIVE: 'success',
  DELETED: 'error',
  PAUSED: 'warning',
  'TEST READY': 'default'
}
