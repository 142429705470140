import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Input, Modal, Popconfirm, Table } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import { SingleInputModal } from 'src/Modules/Common/Components/SingleInputModal'
import {
  SetDocumentKVP,
  SetSchemaKVP,
  DeleteDocumentKVP,
  DeleteSchemaKVP
} from 'src/Modules/Graphql/MetaDataManager/Mutations'
import {
  GetDocumentKvps,
  GetSchemaKvps
} from 'src/Modules/Graphql/MetaDataManager/Queries'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'

type Kvp = {
  id: number
  key: string
  value: string
}

/**
 * Page which displays the key value pairs of a schema or document and allows them to be edited.
 * @param props Properties of the edior.
 */
export function KvpEditor(
  props: { schemaId: number } | { documentId: number }
) {
  const [kvps, setKvps] = useState<Kvp[]>()
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false)
  const [newKey, setNewKey] = useState<string>()
  const [newValue, setNewValue] = useState<string>()

  /** Gets and Sets the key value pairs of this schema or document. */
  const LoadKvps = useCallback(async () => {
    const kvps =
      'schemaId' in props
        ? await GetSchemaKvps(props.schemaId)
        : await GetDocumentKvps(props.documentId)

    if (kvps === undefined) {
      setKvps([])
      return
    }

    setKvps(
      kvps.map((kvp) => ({
        id:
          'schemaId' in props ? (kvp as any).schemaId : (kvp as any).documentId,
        key: kvp.key,
        value: kvp.value
      }))
    )
  }, [props])

  useEffect(() => {
    LoadKvps()
  }, [LoadKvps])

  if (kvps === undefined) return <CenteredCustomSpinner />

  return (
    <TabContentWrapper>
      <Table dataSource={kvps} pagination={false}>
        <Table.Column
          title='Key'
          dataIndex='key'
          key='key'
          sortDirections={['ascend', 'descend']}
          sorter={(a: string, b: string) => (a < b ? 0 : 1)}
        />
        <Table.Column
          title='Value'
          dataIndex='value'
          key='value'
          render={(_, record: Kvp) => (
            <KvpRow keyName={record.key} value={record.value} />
          )}
        />
      </Table>
      <Button type='link' onClick={() => setAddModalVisible(true)}>
        + Add new key value pair.
      </Button>
      <Modal
        title='Add new Key Value Pair'
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={() => {
          if (!newKey || !newValue) {
            NotificationError("Can't add kvp", 'Please fill in all fields.')
            return
          }
          AddKvp(newKey, newValue)
          setNewKey(undefined)
          setNewValue(undefined)
          setAddModalVisible(false)
        }}
      >
        <Input
          value={newKey}
          placeholder='Key'
          style={{ marginBottom: 5 }}
          onChange={(e) => setNewKey(e.currentTarget.value)}
        />
        <Input
          value={newValue}
          placeholder='Value'
          style={{ marginBottom: 5 }}
          onChange={(e) => setNewValue(e.currentTarget.value)}
        />
      </Modal>
    </TabContentWrapper>
  )

  /**
   * Adds a key value pair to the current schema or document and reloads the page.
   * @param key Key of the kvp.
   * @param value Value of the kvp.
   * @param unique Whether the value should be unique for this key.
   * @param force Whether to force the add if it conflicts with current values.
   */
  async function AddKvp(key: string, value: string) {
    'schemaId' in props
      ? await SetSchemaKVP(props.schemaId, key, value)
      : await SetDocumentKVP(props.documentId, key, value)

    await LoadKvps()
  }

  /**
   * Removes value from kvp of this schema or document.
   * @param key Key of the kvp to remove from.
   */
  async function RemoveKvp(key: string) {
    'schemaId' in props
      ? await DeleteSchemaKVP(props.schemaId, key)
      : await DeleteDocumentKVP(props.documentId, key)

    await LoadKvps()
  }

  /**
   * Renders a cell containing an unique value for the given key, including edit operators.
   * @param key Key of the kvp.
   * @param value Value of the kvp.
   * @returns Table cell.
   */
  function KvpRow({ keyName, value }: { keyName: string; value: string }) {
    const [editVisible, setEditVisible] = useState<boolean>(false)

    return (
      <>
        <KvpRowWrapper>
          <KvpRowSpan>{value}</KvpRowSpan>
          <Button type='text' onClick={() => setEditVisible(true)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title='Are you sure you want to delete this kvp?'
            onConfirm={() => RemoveKvp(keyName)}
          >
            <Button type='text'>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </KvpRowWrapper>
        <SingleInputModal
          title='Edit Kvp Value'
          visible={editVisible}
          onOk={(value) => {
            if (value === undefined)
              NotificationError(
                "Can't create empty value.",
                "Can't add a value which is empty."
              )
            else AddKvp(keyName, value)
            setEditVisible(false)
          }}
          onCancel={() => setEditVisible(false)}
          initialInput={value}
        />
      </>
    )
  }
}

// Styling
const KvpRowWrapper = styled.div`
  display: flex;
  align-items: center;
`

const KvpRowSpan = styled.span`
  flex-grow: 1;
`
