import { GetSchemaNames } from 'src/Modules/Graphql/MetaDataManager/Queries'
import { CreateDataloader } from 'src/Modules/Home/Hooks/Dataloader'
import { CreateDataloadedQuery } from 'src/Modules/Home/Hooks/QueryClient'

const SchemaNameDataloader = CreateDataloader(GetSchemaNames, (schemaName) => [
  schemaName.schemaId,
  schemaName
])

const { useSingle, useMultiple, fetchMultiple, invalidateMultiple } =
  CreateDataloadedQuery('schemaName', SchemaNameDataloader)

export const useSchemaName = useSingle
export const useSchemaNames = useMultiple
export const fetchSchemaNames = fetchMultiple
export const invalidateSchemaNames = invalidateMultiple
