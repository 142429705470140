import { Button } from 'antd'
import React, { useState } from 'react'

import JsonEditor from 'src/Modules/Common/Components/JsonEditor'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'

type CodeEditorProps = {
  json?: any
  submitText: string
  onSubmit: (formData: any) => void
}

/**
 * A just code editor
 * @param props props
 * @returns a component
 */
export default function CodeEditor(props: CodeEditorProps) {
  const [json, setJson] = useState<string>()
  return (
    <TabContentWrapper
      bottom={
        <Button type='primary' onClick={() => OnClick()}>
          {props.submitText}
        </Button>
      }
    >
      <JsonEditor
        options={{
          mode: 'code',
          modes: ['view', 'code', 'preview'],
          onChangeText: (json) => setJson(json)
        }}
        json={props.json}
      />
    </TabContentWrapper>
  )

  /** Method Called when the submit button is clicked. */
  function OnClick() {
    try {
      props.onSubmit(json !== undefined ? JSON.parse(json) : props.json)
    } catch (e: any) {
      NotificationError('JSON Parse error', 'Please provide a valid json', e)
    }
  }
}
