import { EditOutlined, ReloadOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { FieldProps, UiSchema } from '@rjsf/core'
import { Button, Drawer, Empty, InputNumber, Modal, Select } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { GetSchemas } from 'src/Modules/Graphql/DocumentManager/Queries'
import { GetSchemaNames } from 'src/Modules/Graphql/MetaDataManager/Queries'
import { NoMarginDivider } from 'src/Modules/Home/Components/Title/NoMarginDivider'
import { DocumentCreator } from 'src/Modules/Home/Containers/DocumentCreator'
import { DocumentEditor } from 'src/Modules/Home/Containers/DocumentEditor'
import { invalidateDocuments } from 'src/Modules/Home/Hooks/Document'
import { useDocumentNames } from 'src/Modules/Home/Hooks/DocumentName'
import { invalidateSchemas, useSchema } from 'src/Modules/Home/Hooks/Schema'
import { parentDocumentIdParam } from 'src/Modules/Utilities/Environment'

type DrawerContent =
  | {
      mode: 'CreateDocument'
      schemaId: number
    }
  | {
      mode: 'EditDocument'
      documentId: number
    }

/**
 * Custom number field for rjsf that checks if the number is a reference and creates input panels accordingly
 * @param props Properties from the react json schema form
 * @returns A custom number field
 */
export default function CustomNumberField(props: FieldProps<any>) {
  const schema: any = props.schema
  const value: number | undefined = props.formData as number | undefined
  if (schema.isSchemaRef)
    return (
      <SchemaSelect
        value={value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    )
  if (schema.isDocumentRef && schema.documentImplementsSchema)
    return (
      <DocumentSelect
        uiSchema={props.uiSchema}
        disabled={props.disabled}
        value={value}
        onChange={props.onChange}
        schemaId={schema.documentImplementsSchema!}
      />
    )

  return (
    <BlockInputNumber value={value} onChange={(e: any) => props.onChange(e)} />
  )
}

/**
 * Component to display a select of available schemas
 * @param props Props containing onchange for the select
 * @returns The select component with an option per schema
 */
function SchemaSelect(props: {
  value?: number
  onChange: (e: any) => void
  disabled: boolean
}) {
  const { data: schemas } = useQuery(
    ['allSchemasWithNames'],
    async () => {
      const schemasResult = await GetSchemas()
      const schemaIds = schemasResult.map((schema) => schema._id) ?? []

      const schemaNames = await GetSchemaNames(schemaIds)

      return _.zip(schemaIds, schemaNames).map(([schemaId, schemaName]) => ({
        id: schemaId,
        label: schemaName?.value
      }))
    },
    {
      cacheTime: Infinity
    }
  )

  return (
    <BlockSelect
      showSearch
      disabled={props.disabled}
      defaultValue={props.value}
      value={props.value}
      onChange={props.onChange}
      optionFilterProp='children'
    >
      {schemas?.map((schema) => (
        <Select.Option key={schema.id} value={schema.id}>
          {schema.label ? `${schema.label} (${schema.id})` : `${schema.id}`}
        </Select.Option>
      ))}
    </BlockSelect>
  )
}

/**
 * Component to display a select of available documents
 * @param props Props containing onchange for the select and a possible schema that the document must implement
 * @returns The select component with an option per document
 */
function DocumentSelect(props: {
  value?: number
  onChange: (e: any) => void
  schemaId: number
  disabled: boolean
  uiSchema: UiSchema
}) {
  const { search } = useLocation()

  const selectRef = useRef<RefSelectProps>(null)
  const [drawerState, setDrawerState] = useState<DrawerContent>()

  const [open, setOpen] = useState(false)

  const { data: schema, isFetching: schemaLoading } = useSchema(props.schemaId)

  const { data: documentNames, isFetching: documentsLoading } =
    useDocumentNames(schema?.documents ?? [], { enabled: !schemaLoading })

  const loading = schemaLoading || documentsLoading

  /** Gets the documents and their names. */
  const invalidateInformation = useCallback(async () => {
    await invalidateSchemas([props.schemaId])
    if (!schema) return
    await invalidateDocuments(schema.documents.map((document) => document))
  }, [props.schemaId, schema])

  // use the ui schema to determine whether the parent id should be used as a default value.
  const useParentIdAsDefault = useMemo(
    () => (props.uiSchema as any)['useParentIdAsDefault'],
    [props.uiSchema]
  )

  // Read the parent document id from the url and use it as default value.
  const documentId = useMemo(() => {
    if (!useParentIdAsDefault || loading) return undefined
    const documentIdString = new URLSearchParams(search).get(
      parentDocumentIdParam
    )
    if (!documentIdString) return undefined
    const documentId = parseInt(documentIdString)
    if (documentNames!.some((document) => document!.id === documentId))
      return documentId
    return undefined
  }, [documentNames, loading, useParentIdAsDefault, search])

  // On the first load of a document id,
  // we want to call props on change to tell the parent library of this value.
  const [usedParentId, setUsedParentId] = useState(false)
  useEffect(() => {
    if (!usedParentId && documentId) {
      props.onChange(documentId)
      setUsedParentId(true)
    }
  }, [documentId, props, usedParentId])

  const defaultValue = props.value ?? documentId

  return (
    <>
      <SelectWrapper>
        <BlockSelect
          loading={loading}
          ref={selectRef}
          open={open}
          onClick={() => {
            if (!drawerState) setOpen((open) => !open)
          }}
          onBlur={() => {
            if (!drawerState) setOpen(false)
          }}
          showSearch
          disabled={props.disabled || loading}
          defaultValue={defaultValue}
          value={loading ? undefined : defaultValue}
          onChange={(e) => {
            console.log(e)
            props.onChange(e)
          }}
          optionFilterProp='label'
          dropdownRender={(menu: JSX.Element) => (
            <SelectMenu>
              <div>{menu}</div>
              <NoMarginDivider />
              <MarginButton
                onMouseDown={() => {
                  setDrawerState({
                    mode: 'CreateDocument',
                    schemaId: props.schemaId
                  })
                }}
              >
                + Create item
              </MarginButton>
            </SelectMenu>
          )}
          dropdownStyle={{ zIndex: 1000 }}
        >
          {_.zip(schema?.documents ?? [], documentNames ?? []).map(
            ([documentId, document]) => {
              const label = document?.name
                ? `${document.name.toString()} (${document.id})`
                : `${documentId}`
              return (
                <Select.Option
                  key={documentId}
                  value={documentId}
                  label={label}
                >
                  <StyledOption>
                    {label}
                    <StyledEditOutlined
                      style={{ color: 'gray' }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setDrawerState({
                          mode: 'EditDocument',
                          documentId: documentId!
                        })
                      }}
                    />
                  </StyledOption>
                </Select.Option>
              )
            }
          )}
        </BlockSelect>
        <Button onClick={() => invalidateInformation()}>
          <ReloadOutlined />
        </Button>
      </SelectWrapper>
      <CustomDrawer
        state={drawerState}
        onOk={() => {
          selectRef.current?.focus()
          setDrawerState(undefined)
        }}
      >
        {drawerState && (
          <ContentPreview
            state={drawerState}
            onSucceed={async (id) => {
              await invalidateInformation()
              props.onChange(id)
              selectRef.current?.focus()
              setDrawerState(undefined)
            }}
          />
        )}
      </CustomDrawer>
    </>
  )
}

/**
 * Custom Drawer component to preview/create documents.
 * @param props Drawer content, children and function to call on ok.
 * @returns Antd Drawer.
 */
function CustomDrawer(props: {
  state?: DrawerContent
  onOk: () => void
  children: React.ReactNode
}) {
  return (
    <Drawer
      visible={props.state !== undefined}
      onClose={() =>
        Modal.confirm({
          title: 'Close drawer?',
          content: 'Closing this drawer will discard all changes.',
          onOk: () => props.onOk()
        })
      }
      width='80%'
      push={{ distance: '20%' }}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      {props.children}
    </Drawer>
  )
}

/**
 * Function that returns the right drawer content based on drawer state.
 * @param props Drawer content and function to call on succeed.
 * @returns Content to put in the antd drawer.
 */
function ContentPreview(props: {
  state: DrawerContent
  onSucceed: (id: number) => void
}) {
  switch (props.state.mode) {
    case 'EditDocument':
      return (
        <DocumentEditor
          key={props.state.documentId}
          documentId={props.state.documentId}
          onSucceed={(id) => props.onSucceed(id)}
        />
      )
    case 'CreateDocument':
      return (
        <DocumentCreator
          key={props.state.schemaId}
          schemaId={props.state.schemaId}
          onSucceed={(id) => props.onSucceed(id)}
        />
      )
    default:
      return <Empty />
  }
}

// Styling
const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  :hover {
    //Set edit button visible
    .anticon-edit {
      visibility: visible;
    }
  }
`

const BlockInputNumber = styled(InputNumber)`
  display: block;
`

const BlockSelect = styled(Select)`
  min-width: 200px;
  display: block;
  flex-grow: 1;
  margin-right: 15px;
`

const SelectWrapper = styled.div`
  display: flex;
`

const SelectMenu = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: stretch;
  grid-row-gap: 5px;
`

const MarginButton = styled(Button)`
  margin: 0 5px;
`

const StyledEditOutlined = styled(EditOutlined)`
  margin-left: 10px;
  visibility: hidden;
`
