import React from 'react'

import {
  DeleteSchemaKVP,
  SetSchemaKVP
} from 'src/Modules/Graphql/MetaDataManager/Mutations'
import {
  BigRenameButton,
  RenameButtonProps
} from 'src/Modules/Home/Components/NodeOptionButtons/RenameButton/RenameButton'
import { invalidateSchemaNames } from 'src/Modules/Home/Hooks/SchemaName'
import { executeSafelyAsync } from 'src/Modules/Utilities/ErrorHandler'

/**
 * Schema rename button
 * @param props props
 * @returns A button to rename a schema
 */
export function RenameSchemaButton(
  props: {
    schemaId: number
  } & RenameButtonProps
) {
  return <BigRenameButton {...props} onOk={(value) => handleOk(value)} />

  /** Method called when the rename modal is submitted */
  async function handleOk(value?: string) {
    await executeSafelyAsync(async () => {
      if (value) await SetSchemaKVP(props.schemaId, 'name', value)
      else if (props.name) await DeleteSchemaKVP(props.schemaId, 'name')
      else return

      await invalidateSchemaNames([props.schemaId])
      props.onSuccess?.(value)
    })
  }
}
