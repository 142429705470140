import { Widgets as antdWidgets } from '@rjsf/antd'
import FormCore, { FormProps } from '@rjsf/core'
import React from 'react'

import CollapseField from 'src/Modules/RJSF/Components/CollapsibleField'
import { CustomArray } from 'src/Modules/RJSF/Components/CustomArrayField'
import CustomNumberField from 'src/Modules/RJSF/Components/CustomNumberField'
import CustomFileWidget from 'src/Modules/RJSF/Components/FileWidget'
import LinkField from 'src/Modules/RJSF/Components/LinkField'
import { MultiSchemaField } from 'src/Modules/RJSF/Components/MultiSchemaField'
import SyspassField from 'src/Modules/RJSF/Components/SyspassField'

/** Wrapper for react json schema form that catches errors and adds custom fields */
export default function Form(props: FormProps<any>) {
  const fields = {
    NumberField: CustomNumberField,
    ArrayField: CustomArray,
    AnyOfField: MultiSchemaField,
    OneOfField: MultiSchemaField
  }

  const widgets = {
    CheckboxesWidget: antdWidgets.CheckboxesWidget,
    CheckboxWidget: antdWidgets.CheckboxWidget,
    ColorWidget: antdWidgets.ColorWidget,
    DateTimeWidget: antdWidgets.DateTimeWidget,
    DateWidget: antdWidgets.DateWidget,
    EmailWidget: antdWidgets.EmailWidget,
    PasswordWidget: antdWidgets.PasswordWidget,
    RadioWidget: antdWidgets.RadioWidget,
    RangeWidget: antdWidgets.RangeWidget,
    SelectWidget: antdWidgets.SelectWidget,
    TextareaWidget: antdWidgets.TextareaWidget,
    TextWidget: antdWidgets.TextWidget,
    UpDownWidget: antdWidgets.UpDownWidget,
    URLWidget: antdWidgets.URLWidget,

    FileWidget: CustomFileWidget
  }

  const templates = {
    Collapse: CollapseField,
    Syspass: SyspassField,
    Link: LinkField
  }

  return (
    <FormCore
      {...{ ...props, uiSchema: replaceTemplates(props.uiSchema) }}
      fields={fields}
      widgets={widgets}
    >
      <div />
    </FormCore>
  )

  /**
   * Replace templates strings with their actual types.
   * @param uiSchema The uiSchema to replace all strings with real types from
   * @returns nothing
   */
  function replaceTemplates(uiSchema?: any) {
    if (typeof uiSchema !== 'object' || Array.isArray(uiSchema)) return uiSchema
    const newUiSchema = { ...uiSchema }

    for (const key in newUiSchema)
      if (typeof newUiSchema[key] === 'object')
        newUiSchema[key] = replaceTemplates(newUiSchema[key])
      else if (key === 'ui:ObjectFieldTemplate' || key === 'ui:FieldTemplate')
        newUiSchema[key] =
          //@ts-expect-error key might not exist, but undefined is fine
          templates[newUiSchema[key]]

    return newUiSchema
  }
}
