import { useMsal } from '@azure/msal-react'
import { Alert, Button } from 'antd'
import React from 'react'

import { CenteringDiv } from 'src/Modules/Utilities/Components/Centering/CenteringDiv'
import { LoginScope } from 'src/Modules/Utilities/Environment'

/** A message displaying the user needs to be logged in */
export default function NeedLogin() {
  const { instance } = useMsal()

  /**
   * Handle login button
   * @param instance the instance to login.
   */
  function handleLogin() {
    instance.loginPopup(LoginScope)
  }

  return (
    <CenteringDiv>
      <Alert
        type='error'
        message='Login'
        description='You need to be logged-in to view this page.'
        action={<Button onClick={() => handleLogin()}>Login</Button>}
      />
    </CenteringDiv>
  )
}
