import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { searchParam, treeParam } from 'src/Modules/Utilities/Environment'

/**
 * Link which keeps the current tree and search query params.
 * @param props where to route to.
 * @returns A link with a param.
 */
export function LinkWithDefaultParams(props: {
  to: string
  search?: [string, string][]
  children: React.ReactNode
  className?: string
}) {
  return <LinkWithParams {...props} paramsToKeep={[treeParam, searchParam]} />
}

/**
 * Link which keeps the given query params.
 * @param props where to route to.
 * @returns A link with a param.
 */
function LinkWithParams(props: {
  to: string
  search?: [string, string][]
  paramsToKeep: string[]
  children: React.ReactNode
  className?: string
}) {
  const location = useLocation()

  // Optionally add any search params to the link, and concat with ampersand.
  const newSearch = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const newParams = new URLSearchParams()

    // Keep the keep parameters.
    props.paramsToKeep.forEach((key) => {
      const value = params.get(key)
      if (value) newParams.set(key, value)
    })

    // Add the new search params.
    const searchProps = props.search
    if (searchProps)
      searchProps.forEach(([key, value]) => {
        newParams.set(key, value)
      })

    return newParams.toString()
  }, [location.search, props.paramsToKeep, props.search])

  return (
    <Link
      to={{ pathname: props.to, search: newSearch }}
      className={props.className}
    >
      {props.children}
    </Link>
  )
}

export const StyledLink = styled(LinkWithDefaultParams)`
  :link,
  :visited {
    color: #ffffff;
  }

  :hover {
    color: #f5f5f5;
  }

  :active {
    color: #f0f0f0;
  }
`
