import styled from '@emotion/styled'
import React from 'react'

import { NoMarginDivider } from 'src/Modules/Home/Components/Title/NoMarginDivider'

/**
 * Component to wrap the bottom buttons
 */
export function TabContentWrapper(props: {
  children: React.ReactNode
  bottom?: React.ReactNode
}) {
  return (
    <ContentWrapperWrapper>
      <ContentWrapperInnerWrapper>{props.children}</ContentWrapperInnerWrapper>
      {props.bottom && (
        <BottomButtonsWrapper>
          <NoMarginDivider type='horizontal' />
          <BottomButtonsInnerWrapper>{props.bottom}</BottomButtonsInnerWrapper>
        </BottomButtonsWrapper>
      )}
    </ContentWrapperWrapper>
  )
}

const ContentWrapperWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: hidden;
`

const ContentWrapperInnerWrapper = styled.div`
  padding: 10px;
  overflow: auto;
`

const BottomButtonsWrapper = styled.div`
  background: #141414;
`

const BottomButtonsInnerWrapper = styled.div`
  padding: 5px;
  display: grid;
  column-gap: 5px;
  grid-template-columns: repeat(2, auto) 1fr auto;
`
