import { notification } from 'antd'

/**
 * Tries executing a function which might fail and writes an error to an antd notification if it fails.
 * @param unsafeFunction The unsafe error that should be executed.
 * @returns The result of the function if it succeeds
 */
export async function executeSafelyAsync(
  unsafeAsyncFunction: () => Promise<void>,
  message?: string
) {
  try {
    await unsafeAsyncFunction()
  } catch (e: any) {
    NotificationError(message ?? 'Error', e.message, e)
  }
}

/**
 * Tries executing a function which might fail and writes an error to an antd notification if it fails. But still throws the error.
 * @param unsafeFunction The unsafe function that should be executed.
 * @returns The result of the function if it succeeds
 */
export async function executeAsync<T>(
  unsafeAsyncFunction: () => Promise<T>,
  message?: string
) {
  try {
    return await unsafeAsyncFunction()
  } catch (e: any) {
    NotificationError(message ?? 'Error', e.message, e)
    throw e
  }
}

/**
 * Shows an error on the page
 * @param message the title of the message
 * @param description A more detailed error description
 */
export function NotificationError(
  message: string,
  description: string,
  error?: Error
) {
  console.error(message, description, error ?? 'No error provided')
  notification.error({
    message: message,
    description: description,
    placement: 'bottomRight'
  })
}
