import styled from '@emotion/styled'
import { Group, ServicePrincipal, User } from '@microsoft/microsoft-graph-types'
import { Button, Form, Select, Typography } from 'antd'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'
import { useEffect, useState } from 'react'

import { SetSchemaPermissions } from 'src/Modules/Graphql/DocumentManager/Mutations'
import { GetSchemaPermissions } from 'src/Modules/Graphql/DocumentManager/Queries'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import { MSGraphClient } from 'src/Modules/Utilities/Authorization/MSGraphClient'
import { TenantId } from 'src/Modules/Utilities/Environment'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/**
 * Component to manage schema permissions
 * @param props props
 * @returns A component
 */
export default function Permissions(props: { schemaId: number }) {
  const [read, setRead] = useState<string[] | null>()
  const [write, setWrite] = useState<string[] | null>()

  const [users, setUsers] = useState<User[]>()
  const [groups, setGroups] = useState<Group[]>()
  const [servicePrincipals, setServicePrincipals] =
    useState<ServicePrincipal[]>()

  useEffect(() => {
    /**
     * Get objects
     */
    async function effectFunction() {
      MSGraphClient.api('/users')
        .select(['id', 'displayName'])
        .get()
        .then((users) => setUsers(users.value as User[]))
      MSGraphClient.api('/groups')
        .filter('securityEnabled eq true')
        .select(['id', 'displayName'])
        .get()
        .then((group) => setGroups(group.value as Group[]))
      MSGraphClient.api('/servicePrincipals')
        .count(true)
        .header('ConsistencyLevel', 'eventual')
        .filter(`appOwnerOrganizationId eq ${TenantId}`)
        .select(['id', 'displayName'])
        .get()
        .then((servicePrincipal) =>
          setServicePrincipals(servicePrincipal.value as ServicePrincipal[])
        )
    }

    effectFunction()
  }, [])

  useEffect(() => {
    /**
     * Get current permissions
     */
    async function effectFunction() {
      if (!users || !groups || !servicePrincipals) return
      GetSchemaPermissions(props.schemaId).then((response) => {
        const schema = response!.schemas[0]

        const readAccess = schema.readAccess
        if (readAccess) setRead([...readAccess])
        else setRead(null)

        const writeAccess = schema.writeAccess
        if (writeAccess) setWrite([...writeAccess])
        else setWrite(null)
      })
    }
    effectFunction()
  }, [props.schemaId, users, groups, servicePrincipals])

  //We need read and write to be defined, otherwise default props will not be set again.
  if (
    read === undefined ||
    write === undefined ||
    !users ||
    !groups ||
    !servicePrincipals
  )
    return <div></div>

  const OptionGroups = (
    <>
      <Select.OptGroup label='User'>
        {users.map((user) => (
          <Select.Option
            key={user.id!}
            label={user.displayName}
            value={user.id!}
            {...user}
          >
            {user.displayName}
          </Select.Option>
        ))}
      </Select.OptGroup>
      <Select.OptGroup label='Group'>
        {groups.map((group) => (
          <Select.Option
            key={group.id!}
            label={group.displayName}
            value={group.id!}
            {...group}
          >
            {group.displayName}
          </Select.Option>
        ))}
      </Select.OptGroup>
      <Select.OptGroup label='Applications'>
        {servicePrincipals.map((servicePrincipal) => (
          <Select.Option
            key={servicePrincipal.id!}
            label={servicePrincipal.displayName}
            value={servicePrincipal.id!}
            {...servicePrincipal}
          >
            {servicePrincipal.displayName}
          </Select.Option>
        ))}
      </Select.OptGroup>
    </>
  )

  return (
    <TabContentWrapper
      bottom={
        <Button type='primary' onClick={() => SetPermissions()}>
          Set Permissions
        </Button>
      }
    >
      <div>
        <Typography.Title level={4}>Access</Typography.Title>
        <Form>
          <Form.Item label='Read'>
            <WidthSelect
              defaultValue={read ?? undefined}
              mode='multiple'
              showSearch
              filterOption={filterOption}
              placeholder='Read permission'
              onChange={(_, options) => {
                if (Array.isArray(options))
                  setRead(options.map((option) => option.value))
              }}
            >
              {OptionGroups}
            </WidthSelect>
          </Form.Item>
          <Form.Item label='Write'>
            <WidthSelect
              defaultValue={write ?? undefined}
              mode='multiple'
              showSearch
              filterOption={filterOption}
              placeholder='Write permission'
              onChange={(_, options) => {
                if (Array.isArray(options))
                  setWrite(options.map((option) => option.value))
              }}
            >
              {OptionGroups}
            </WidthSelect>
          </Form.Item>
        </Form>
      </div>
    </TabContentWrapper>
  )

  /**
   * Update the permissions on the document manager
   */
  async function SetPermissions() {
    try {
      await SetSchemaPermissions(
        props.schemaId,
        read ?? undefined,
        write ?? undefined,
        true
      )
      NotificationSuccess(
        'Permissions updated',
        'The permissions are updated successfully'
      )
    } catch (e: any) {
      NotificationError('Error', e.message, e)
    }
  }

  /**
   * Filter an option.
   * @param inputValue The filter value.
   * @param option The option getting filtered.
   * @returns Wether the option passes the filter.
   */
  function filterOption(
    inputValue: string,
    option?: DefaultOptionType | BaseOptionType
  ): boolean {
    if (!option?.label) return true
    return option.label.toLowerCase().includes(inputValue.toLowerCase())
  }
}

const WidthSelect = styled(Select)`
  min-width: 200px;
`
