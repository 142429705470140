import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery } from 'react-relay'

import { QueriesPaginatedAlertsQuery } from 'src/Modules/Graphql/LoggingDatabase/__generated__/QueriesPaginatedAlertsQuery.graphql'
import { QueriesPaginatedCoordinatesQuery } from 'src/Modules/Graphql/LoggingDatabase/__generated__/QueriesPaginatedCoordinatesQuery.graphql'
import { QueriesPaginatedMacAddressesQuery } from 'src/Modules/Graphql/LoggingDatabase/__generated__/QueriesPaginatedMacAddressesQuery.graphql'
import { LDRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'

/**
 * Get the paginated statuses from the specific documentId
 * @param documentId The documentIds to get the statuses from
 * @param after The cursor to take items after
 * @param first How many items to take on each page
 * @returns the statuses
 */
export function GetPaginatedAlerts(
  documentIds: number[],
  first?: number,
  after?: string
) {
  const PaginatedAlertsQuery = graphql`
    query QueriesPaginatedAlertsQuery(
      $documentIds: [Int!]
      $first: Int
      $after: String
    ) {
      paginatedAlerts(documentIds: $documentIds, first: $first, after: $after) {
        items {
          _id
          documentId
          dateTime
          type
          title
          message
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `

  return fetchQuery<QueriesPaginatedAlertsQuery>(
    LDRelayEnvironment,
    PaginatedAlertsQuery,
    {
      documentIds: documentIds,
      first: first,
      after: after
    }
  ).toPromise()
}

/**
 * Get the paginated mac adresses from the specific documentId
 * @param documentId The documentIds to get the mac adresses from
 * @param after The cursor to take items after
 * @param first How many items to take on each page
 * @returns the mac addresses
 */
export function GetPaginatedMacAdresses(
  documentIds: number[],
  first?: number,
  after?: string
) {
  const PaginatedMacAddressesQuery = graphql`
    query QueriesPaginatedMacAddressesQuery(
      $documentIds: [Int!]
      $first: Int
      $after: String
    ) {
      paginatedMacAdresses(
        documentIds: $documentIds
        first: $first
        after: $after
      ) {
        items {
          _id
          documentId
          dateTime
          macAddress
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `

  return fetchQuery<QueriesPaginatedMacAddressesQuery>(
    LDRelayEnvironment,
    PaginatedMacAddressesQuery,
    {
      documentIds: documentIds,
      first: first,
      after: after
    }
  ).toPromise()
}

/**
 * Get the paginated coordinates from the specific documentId
 * @param documentId The documentIds to get the coordinates from
 * @param after The cursor to take items after
 * @param first How many items to take on each page
 * @returns the coordinates
 */
export function GetPaginatedCoordinates(
  documentIds: number[],
  first?: number,
  after?: string
) {
  const PaginatedCoordinatesQuery = graphql`
    query QueriesPaginatedCoordinatesQuery(
      $documentIds: [Int!]
      $first: Int
      $after: String
    ) {
      paginatedCoordinates(
        documentIds: $documentIds
        first: $first
        after: $after
      ) {
        items {
          _id
          documentId
          dateTime
          latitude
          longitude
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `

  return fetchQuery<QueriesPaginatedCoordinatesQuery>(
    LDRelayEnvironment,
    PaginatedCoordinatesQuery,
    {
      documentIds: documentIds,
      first: first,
      after: after
    }
  ).toPromise()
}
