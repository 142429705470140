import { AuthenticationResult } from '@azure/msal-common'

import { MsalInstance } from 'src/Modules/Utilities/Authorization/MsalInstance'

/**
 * Retrieve a access token with the given scope
 * @param scopes The scope of the token
 * @returns The access token
 */
export default async function GetAccessToken(scopes: string[]) {
  const request = {
    scopes: scopes,
    account: MsalInstance.getAllAccounts()[0]
  }
  try {
    const response = await MsalInstance.acquireTokenSilent(request)
    return response.accessToken
  } catch (e) {
    if (!acquireTokenPopupCallback)
      acquireTokenPopupCallback = MsalInstance.acquireTokenPopup(request)

    const response = await acquireTokenPopupCallback
    acquireTokenPopupCallback = undefined
    return response.accessToken
  }
}

let acquireTokenPopupCallback: undefined | Promise<AuthenticationResult>
