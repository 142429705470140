import { useState } from 'react'

/**
 * A hook to wrap a loading state.
 */
export function useLoading() {
  const [loading, setLoading] = useState(false)

  /**
   * Wait on the promise to be resolved or n second(s).
   * @param promise The promise to await.
   */
  async function OnLoad<T>(promise: Promise<T>): Promise<T> {
    setLoading(true)
    // Wait for at least 1 second, to prevent clicking multiple times and a jittery ui.
    const delayTask = new Promise((resolve) => setTimeout(resolve, 1000))
    const [result] = await Promise.all([promise, delayTask])
    setLoading(false)
    return result
  }

  return [loading, OnLoad] as const
}
