import { DownloadOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Select } from 'antd'
import DataLoader from 'dataloader'
import download from 'downloadjs'
import jp from 'jsonpath'
import { groupBy, map, mapValues, reduce } from 'lodash'
import React, { useEffect, useState } from 'react'

import CenteredError from 'src/Modules/Common/Components/CenteredError'
import { GetDocumentsJsonAndReferencesBySchemaId } from 'src/Modules/Graphql/DocumentManager/Queries'
import { QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery$data } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery.graphql'
import {
  GetDocumentNames,
  GetSchemaValue
} from 'src/Modules/Graphql/MetaDataManager/Queries'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import { useLoading } from 'src/Modules/Home/Containers/Content/Tabs/Shared/useLoading'
import { useDocument } from 'src/Modules/Home/Hooks/Document'

type propValueType =
  | string
  | {
      path: string
      resolveDocumentName?: boolean
    }

type exportType = {
  name?: string
  props?: { [columnName: string]: propValueType }
  referencedBy?: {
    [schemaId: string]: exportType
  }
  referencing?: {
    [schemaId: string]: exportType
  }
}

type dataLoaderKeyType = {
  documentId: number
  referencing?: number[]
  referencedBy?: number[]
}

type dataLoaderResultType =
  QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery$data['documents'][0]

const exportDocumentsLoader = new DataLoader<
  dataLoaderKeyType,
  dataLoaderResultType
>((keys) => LoadExportDocumentBatched(keys))

const exportDocumentNamesLoader = new DataLoader<number, string>((keys) =>
  LoadExportDocumentNamesBatched(keys)
)

/**
 * Document export page.
 * @param documentId the id of the document to export.
 * @returns An export page.
 */
export function ExportPage({ documentId }: { documentId: number }) {
  const [exportConfigurations, setExportConfigurations] = useState<
    exportType[] | null
  >()
  const [selectedExportConfig, setSelectedExportConfig] =
    useState<exportType | null>()
  const [loadingXml, withLoadingXml] = useLoading()
  const [loadingJson, withLoadingJson] = useLoading()

  const { data: document } = useDocument(documentId)

  useEffect(() => {
    /** Async effect function */
    async function EffectFunction() {
      if (!document) return
      try {
        const exportData = await GetSchemaValue(document?.schemaId, 'export')
        if (exportData === undefined) {
          setExportConfigurations(null)
          return
        }
        const exportConfigurations = JSON.parse(
          exportData.value
        ) as exportType[]
        setExportConfigurations(exportConfigurations)
        setSelectedExportConfig(exportConfigurations[0])
      } catch (error) {
        console.error(error)
      }
    }

    EffectFunction()
  }, [document])

  if (exportConfigurations === null)
    return (
      <CenteredError description='No export configurations specifed, please contact the dev team.' />
    )

  return (
    <TabContentWrapper
      bottom={
        <>
          <Button
            onClick={async () => await withLoadingXml(ExportAndDownloadXml())}
            loading={loadingXml}
          >
            Export XML <DownloadOutlined />
          </Button>
          <Button
            onClick={async () => await withLoadingJson(ExportAndDownloadJson())}
            loading={loadingJson}
          >
            Export JSON <DownloadOutlined />
          </Button>
        </>
      }
    >
      <SelectLabel>Select an export configuration:</SelectLabel>
      <Select<number>
        defaultValue={0}
        onChange={(value) => {
          if (exportConfigurations)
            setSelectedExportConfig(exportConfigurations[value])
        }}
      >
        {exportConfigurations?.map((config, index) => (
          <Select.Option key={index} value={index}>
            {config.name}
          </Select.Option>
        ))}
      </Select>
    </TabContentWrapper>
  )
  /** Generate data to export and download it. */
  async function ExportAndDownloadJson() {
    if (selectedExportConfig) {
      const [, value] = await ExportData(documentId, selectedExportConfig)
      const json = JSON.stringify(value)

      download(json, 'export.json', 'text/json')
    }
  }

  /** Generate data to export and download it. */
  async function ExportAndDownloadXml() {
    if (selectedExportConfig) {
      const [key, value] = await ExportData(documentId, selectedExportConfig)
      const json = JSON.stringify(value)

      const xmljs = await import('xml-js')
      const xml = `<?xml version="1.0" encoding="UTF-8"?> ${
        key ? `<${key}>` : ''
      } ${xmljs.json2xml(json, { compact: true })} ${key ? `</${key}>` : ''}  `

      download(xml, 'export.xml', 'text/xml')
    }
  }

  /**
   * Export data from a document recursively.
   * @param documentId The id of the document to export.
   * @param exportType The export configuration.
   * @returns The key and value of the exported data.
   */
  async function ExportData(
    documentId: number,
    { name, props, referencing, referencedBy }: exportType
  ): Promise<[string | undefined, { [key: string]: any }]> {
    // Load the document json and all reference documents. Using the dataloader to batch requests.
    const document = await exportDocumentsLoader.load({
      documentId,
      referencing: referencing
        ? StringsToNumbers(Object.keys(referencing))
        : undefined,
      referencedBy: referencedBy
        ? StringsToNumbers(Object.keys(referencedBy))
        : undefined
    })

    if (document === undefined) throw new Error()

    // Read all json path values from the document. And store them in a single object with the key as key.
    const ownProperties = props
      ? await Promise.all(
          Object.entries(props).map(async ([key, value]) => {
            const jsonPath = typeof value === 'string' ? value : value.path
            const jsonValue = jp.value(document.versionAt!.json, jsonPath)

            if (typeof value === 'string' || !value.resolveDocumentName)
              return [key, jsonValue]

            const documentName = await exportDocumentNamesLoader.load(jsonValue)
            return [key, documentName]
          })
        )
      : []

    // Load all referencing documents.
    const referencingProperties = referencing
      ? await Promise.all(
          document.versionAt!.referencingDocuments.map((reference) =>
            ExportData(reference._id, referencing[reference.schema._id])
          )
        )
      : []

    // Load all referenced documents.
    const referencedByProperties = referencedBy
      ? await Promise.all(
          document.referencedByDocumentVersions.map((reference) =>
            ExportData(
              reference.document._id,
              referencedBy[reference.document.schema._id]
            )
          )
        )
      : []

    // Separate the named and nameless properties.
    const [namelessEntries, namedEntries] = partition(
      [...ownProperties, ...referencingProperties, ...referencedByProperties],
      ([key]) => key === undefined
    )

    // Create the object with the nameless properties.
    const namelessEntriesValues = Object.assign(
      {},
      ...namelessEntries.map(([, value]) => value)
    )

    // Group by name.
    const groupedEntries = mapValues(
      groupBy(namedEntries, ([name]) => name),
      (nameValueTuples) => {
        return nameValueTuples.map(([, value]) => value)
      }
    )

    // Return a tuple, with name and properties.
    return [name, { ...groupedEntries, ...namelessEntriesValues }]
  }
}

/**
 * Turn an array of string to an array of numbers.
 * @param strings string array
 * @returns number array
 */
function StringsToNumbers(strings: string[]) {
  return strings.map((s) => parseInt(s))
}

/**
 * Batch function to load documents for export, groups requests by referencing arguments.
 * @param keys Keys used in dataloading
 * @returns Result of batch operation
 */
async function LoadExportDocumentBatched(
  keys: readonly dataLoaderKeyType[]
): Promise<dataLoaderResultType[]> {
  // First, group the requests by referencing and referencedBy parameters, as these may differ due to concurrent execution.
  // Use the stringified version of the keys to group them to ensure proper value comparison.
  const keyGroups = groupBy(keys, (key) =>
    JSON.stringify({
      referencing: key.referencing,
      referencedBy: key.referencedBy
    })
  )

  // Then, execute the query for each group, using the first key references, as they all should be the same.
  const results = await Promise.all(
    map(
      keyGroups,
      async (dataLoaderKeys) =>
        await GetDocumentsJsonAndReferencesBySchemaId(
          dataLoaderKeys.map((dataLoaderKey) => dataLoaderKey.documentId),
          dataLoaderKeys[0].referencing,
          dataLoaderKeys[0].referencedBy
        )
    )
  )

  // Create a dictionary of results using the resulting document id, to find them back before returning.
  const idResultDict = Object.fromEntries(
    results.flatMap((result) =>
      result.map((document) => [document._id, document])
    )
  )

  // Finally, get the corresponding $data per key and return.
  return keys.map((key) => idResultDict[key.documentId])
}

/**
 * Batch function to load documents for export, groups requests by referencing arguments.
 * @param keys Keys used in dataloading
 * @returns Result of batch operation
 */
async function LoadExportDocumentNamesBatched(
  keys: readonly number[]
): Promise<string[]> {
  const results = await GetDocumentNames([...keys])

  // Create a dictionary of results using the resulting document id, to find them back before returning.
  const idResultDict = Object.fromEntries(
    results.map((result) => [result.id, result.name])
  )

  // Finally, get the corresponding $data per key and return.
  return keys.map((key) => idResultDict[key].toString())
}

/**
 * Partition an array into two arrays based on a predicate.
 * @param collection The collection to partition.
 * @param predicate The predicate to use for partitioning.
 * @returns A tuple of two arrays, the first containing the elements that satisfy the predicate, the second containing the elements that do not.
 */
function partition<T>(
  collection: T[],
  predicate: (value: T) => boolean
): [T[], T[]] {
  return reduce<T, [T[], T[]]>(
    collection,
    ([truthy, falsey], item) => {
      return predicate(item)
        ? [[item, ...truthy], falsey]
        : [truthy, [item, ...falsey]]
    },
    [[], []]
  )
}

// Styling
const SelectLabel = styled.span`
  margin-right: 10px;
`
