import React from 'react'
import { useNavigate } from 'react-router-dom'

import { SchemaCreator } from 'src/Modules/DevPage/Components/SchemaCreator'

/**
 * Function that wraps schemaCreator so we can use the useNavigate hook
 */
export function SchemaCreatorWrapper() {
  const navigate = useNavigate()

  return (
    <SchemaCreator onSucceed={(id) => navigate(`/development/schema/${id}`)} />
  )
}
