import Menu from 'antd/lib/menu'
import React from 'react'

import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { CardTitle } from 'src/Modules/Home/Components/Card/CardTitle'
import { StyledCard } from 'src/Modules/Home/Components/Card/CardWrapper'
import { TitleDropdown } from 'src/Modules/Home/Components/Card/TitleDropdown'
import { CardTitleWrapper } from 'src/Modules/Home/Components/Card/TitleWrapper'
import { DeleteSchemaButton } from 'src/Modules/Home/Components/NodeOptionButtons/DeleteButton/DeleteSchemaButton'
import { useSchemaName } from 'src/Modules/Home/Hooks/SchemaName'

/**
 * Creates card for a schema
 * @param props the id of the schema.
 * @returns A card representing the scema.
 */
export function SchemaCard(props: { schemaId: number }) {
  const route = UseRoute()
  const { data: schemaName } = useSchemaName(props.schemaId, { suspense: true })

  return (
    <StyledCard
      title={
        <CardTitleWrapper>
          <CardTitle
            name={schemaName?.value?.toString() ?? props.schemaId}
            to={`/${route}/schema/${props.schemaId}`}
          />
          <TitleDropdown>
            <Menu>
              <Menu.Item>
                <DeleteSchemaButton schemaId={props.schemaId} />
              </Menu.Item>
            </Menu>
          </TitleDropdown>
        </CardTitleWrapper>
      }
      size='small'
    >
      Placeholder
    </StyledCard>
  )
}
