import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

/** Custom spinner icon */
export default function CustomSpinner(props: { className?: string }) {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      className={props.className}
    />
  )
}
