import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'

import {
  MutationsDeleteDocumentKVPMutation,
  MutationsDeleteDocumentKVPMutation$data
} from 'src/Modules/Graphql/MetaDataManager/__generated__/MutationsDeleteDocumentKVPMutation.graphql'
import {
  MutationsDeleteSchemaKVPMutation,
  MutationsDeleteSchemaKVPMutation$data
} from 'src/Modules/Graphql/MetaDataManager/__generated__/MutationsDeleteSchemaKVPMutation.graphql'
import {
  MutationsSetDocumentKVPMutation,
  MutationsSetDocumentKVPMutation$data
} from 'src/Modules/Graphql/MetaDataManager/__generated__/MutationsSetDocumentKVPMutation.graphql'
import {
  MutationsSetSchemaKVPMutation,
  MutationsSetSchemaKVPMutation$data
} from 'src/Modules/Graphql/MetaDataManager/__generated__/MutationsSetSchemaKVPMutation.graphql'
import { MMRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'

/**
 * Add a new kvp to a schema
 * @param schemaId The id of the schema to attach the kvp to
 * @param key The key of the kvp
 * @param value The value of the kvp
 * @returns The new schema kvp
 */
export async function SetSchemaKVP(
  schemaId: number,
  key: string,
  value: string
) {
  const mutation = graphql`
    mutation MutationsSetSchemaKVPMutation(
      $id: Int!
      $key: String!
      $value: String!
    ) {
      setSchemaKVP(id: $id, key: $key, value: $value) {
        _id
        value
      }
    }
  `

  return new Promise<MutationsSetSchemaKVPMutation$data['setSchemaKVP']>(
    (resolve, reject) => {
      commitMutation<MutationsSetSchemaKVPMutation>(MMRelayEnvironment, {
        mutation: mutation,
        variables: {
          id: schemaId,
          key: key,
          value: value
        },
        onError: reject,
        onCompleted: ($data) => {
          resolve($data.setSchemaKVP)
        }
      })
    }
  )
}

/**
 * Remove a kvp from a schema
 * @param schemaId The id of the schema to attach the kvp to
 * @param key The key of the kvp
 * @returns The new schema kvp
 */
export async function DeleteSchemaKVP(schemaId: number, key: string) {
  const mutation = graphql`
    mutation MutationsDeleteSchemaKVPMutation($id: Int!, $key: String!) {
      deleteSchemaKVP(id: $id, key: $key)
    }
  `

  return new Promise<MutationsDeleteSchemaKVPMutation$data['deleteSchemaKVP']>(
    (resolve, reject) => {
      commitMutation<MutationsDeleteSchemaKVPMutation>(MMRelayEnvironment, {
        mutation: mutation,
        variables: {
          id: schemaId,
          key: key
        },
        onError: reject,
        onCompleted: ($data) => {
          resolve($data.deleteSchemaKVP)
        }
      })
    }
  )
}

/**
 * Sets kvp for a document
 * @param documentId The id of the document to attach the kvp to
 * @param key The key of the kvp
 * @param value The value of the kvp
 * @returns The new document kvp
 */
export async function SetDocumentKVP(
  documentID: number,
  key: string,
  value: string
) {
  const mutation = graphql`
    mutation MutationsSetDocumentKVPMutation(
      $id: Int!
      $key: String!
      $value: String!
    ) {
      setDocumentKVP(id: $id, key: $key, value: $value) {
        _id
        value
      }
    }
  `

  return new Promise<MutationsSetDocumentKVPMutation$data['setDocumentKVP']>(
    (resolve, reject) => {
      commitMutation<MutationsSetDocumentKVPMutation>(MMRelayEnvironment, {
        mutation: mutation,
        variables: {
          id: documentID,
          key: key,
          value: value
        },
        onError: reject,
        onCompleted: ($data) => {
          resolve($data.setDocumentKVP)
        }
      })
    }
  )
}

/**
 * Remove a  kvp from a document
 * @param documentId The id of the document to attach the kvp to
 * @param key The key of the kvp
 * @returns The new document kvp
 */
export async function DeleteDocumentKVP(schemaId: number, key: string) {
  const mutation = graphql`
    mutation MutationsDeleteDocumentKVPMutation($id: Int!, $key: String!) {
      deleteDocumentKVP(id: $id, key: $key)
    }
  `

  return new Promise<
    MutationsDeleteDocumentKVPMutation$data['deleteDocumentKVP']
  >((resolve, reject) => {
    commitMutation<MutationsDeleteDocumentKVPMutation>(MMRelayEnvironment, {
      mutation: mutation,
      variables: {
        id: schemaId,
        key: key
      },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.deleteDocumentKVP)
      }
    })
  })
}

/**
 * Set a uischema
 * @param schemaId Id of the schema
 * @param uiSchema Stringified UiSchema json
 * @returns The new uischema
 */
export async function SetUiSchema(schemaId: number, uiSchema: string) {
  return SetSchemaKVP(schemaId, 'uiSchema', uiSchema)
}
