import React, { useEffect, useState } from 'react'

import { GetSchemaJson } from 'src/Modules/Graphql/DocumentManager/Queries'
import CodeEditor from 'src/Modules/Home/Components/Editors/CodeEditor'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'

/**
 * A schema code editor
 * @returns A schema code editor
 */
export function SchemaCodeEditor(props: {
  schemaId: number
  onSubmit: (json: any) => void
}) {
  const [schema, setSchema] = useState<any>()

  useEffect(() => {
    /** Async function wrapper */
    async function effectFunction() {
      // Get json
      const schemaJson = await GetSchemaJson(props.schemaId)
      setSchema(schemaJson)
    }

    effectFunction()
  }, [props.schemaId])

  if (!schema) return <CenteredCustomSpinner />

  return (
    <CodeEditor
      json={schema}
      onSubmit={(json) => props.onSubmit(json)}
      submitText='Save'
    />
  )
}
