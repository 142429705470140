import React from 'react'

import { RemoveSchema } from 'src/Modules/Graphql/DocumentManager/Mutations'
import { DeleteButton } from 'src/Modules/Home/Components/NodeOptionButtons/DeleteButton/DeleteButton'
import { invalidateSchemas, useSchema } from 'src/Modules/Home/Hooks/Schema'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/**
 * Delete schema button.
 * @param props Prop with schema id.
 * @returns A component to remove a schema.
 */
export function DeleteSchemaButton(props: {
  schemaId: number
  className?: string
}) {
  const { refetch } = useSchema(props.schemaId, { enabled: false })

  return (
    <DeleteButton
      buttonProps={{
        className: props.className,
        onClick: (e) => e.stopPropagation()
      }}
      resourceName={props.schemaId.toString()}
      onConfirmDelete={() => RemoveThisSchema()}
    />
  )

  /** Remove this schema from the document manager */
  async function RemoveThisSchema() {
    const { data: schema } = await refetch()
    if (schema!.documents.length > 0) {
      NotificationError(
        'Blocked by documents',
        "Can't delete schema since it still has active documents"
      )
      return
    }

    try {
      await RemoveSchema(props.schemaId)
      NotificationSuccess('Deleted', 'Schema successfully deleted')
      await invalidateSchemas([props.schemaId])
    } catch (e: any) {
      NotificationError('Error on Schema Delete', e.message, e)
    }
  }
}
