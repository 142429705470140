import { Tooltip } from 'antd'
import React from 'react'

import { SendSms } from 'src/Modules/Graphql/Eview/Mutations'
import { MarginButton } from 'src/Modules/Home/Containers/Content/Tabs/Shared/MarginButton'
import { executeAsync } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/**
 * A hook to send sms to the eview integration
 * @param schemaId the schemaId of the current document
 * @param documentId the documentId of the current document
 * @returns undefined if the document is not an eview button or a function to send an sms
 */
export default function EviewSms(props: { documentId: number }) {
  return (
    <Tooltip title='Send an sms to the button in order to configure the ip, port and APN'>
      <MarginButton size='small' onClick={SendSmsNotification}>
        Send SMS
      </MarginButton>
    </Tooltip>
  )

  /**
   * Send a notification to the eview integration to send an sms
   */
  async function SendSmsNotification() {
    const number = await executeAsync(
      async () => await SendSms(props.documentId),
      'Sending SMS failed'
    )
    NotificationSuccess(
      'Sent SMS successfully',
      `Send an sms to an eview button ${
        number && `with number ${number} successfully`
      }`
    )
  }
}
