import { ObjectFieldTemplateProps } from '@rjsf/core'
import { Collapse } from 'antd'
import React from 'react'

/**
 * A collapsible field object template
 * @param props the props for this template
 * @returns the field
 */
export default function CollapsibleField(props: ObjectFieldTemplateProps) {
  return (
    <div>
      <props.TitleField {...props} id={props.idSchema.$id} />
      <props.DescriptionField {...props} id={props.idSchema.$id} />
      {/* Find all the names of the collapsed children and make those non active*/}
      <Collapse
        defaultActiveKey={props.properties
          .filter((element) => !element.content.props.uiSchema['ui:collapsed'])
          .map((element) => element.name)}
      >
        {/* Render all the children */}
        {props.properties.map((element) => (
          <Collapse.Panel header={element.name} key={element.name}>
            {element.content}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  )
}
