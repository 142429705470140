import { Collapse } from 'antd'
import React from 'react'

import { SchemaCard } from 'src/Modules/DevPage/Components/SchemaCard'
import { CardsWrapper } from 'src/Modules/Home/Components/Card/CardWrapper'
import { DocumentCard } from 'src/Modules/Home/Components/Card/DocumentCard'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import { useGroupDocumentsBySchemaName } from 'src/Modules/Home/Containers/Content/Tabs/ReferencesPage/useDocumentReferences'
import { useSchema } from 'src/Modules/Home/Hooks/Schema'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import CenteredEmpty from 'src/Modules/Utilities/Components/Centering/CenteredEmpty'
import { SuspenseAndBoundary } from 'src/Modules/Utilities/Components/SuspenseAndBoundary'

/**
 * A page to find all references for a schema
 * @param props Props that determine the behavior of this component.
 * @returns A component with cards for all references.
 */
export default function SchemaReferencesPage(props: { schemaId: number }) {
  const { data: schema } = useSchema(props.schemaId, { suspense: true })

  const referencedByDocuments = useGroupDocumentsBySchemaName(
    schema!.referencedByDocuments
  )

  if (!schema || !referencedByDocuments) return <CenteredCustomSpinner />

  return (
    <TabContentWrapper>
      <Collapse
        defaultActiveKey={[
          'referencingSchemas',
          'referencedBySchemas',
          'referencedByDocuments'
        ]}
      >
        <Collapse.Panel header='referencingSchemas' key='referencingSchemas'>
          {schema.referencingSchemas.length === 0 ? (
            <CenteredEmpty />
          ) : (
            <SuspenseAndBoundary>
              <CardsWrapper>
                {schema.referencingSchemas.map((schema) => (
                  <SchemaCard schemaId={schema} key={schema} />
                ))}
              </CardsWrapper>
            </SuspenseAndBoundary>
          )}
        </Collapse.Panel>
        <Collapse.Panel header='referencedBySchemas' key='referencedBySchemas'>
          {schema.referencedBySchemas.length === 0 ? (
            <CenteredEmpty />
          ) : (
            <SuspenseAndBoundary>
              <CardsWrapper>
                {schema.referencedBySchemas.map((schema) => (
                  <SchemaCard schemaId={schema} key={schema} />
                ))}
              </CardsWrapper>
            </SuspenseAndBoundary>
          )}
        </Collapse.Panel>
        <Collapse.Panel
          header='referencedByDocuments'
          key='referencedByDocuments'
        >
          {schema.referencedByDocuments.length === 0 ? (
            <CenteredEmpty />
          ) : (
            <Collapse>
              {referencedByDocuments.map(([schemaName, documentIds]) => (
                <Collapse.Panel header={schemaName} key={schemaName}>
                  <SuspenseAndBoundary>
                    <CardsWrapper>
                      {documentIds.map((documentId) => (
                        <DocumentCard
                          documentId={documentId}
                          key={documentId}
                        />
                      ))}
                    </CardsWrapper>
                  </SuspenseAndBoundary>
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </Collapse.Panel>
      </Collapse>
    </TabContentWrapper>
  )
}
