export class DocumentName {
  private name: string

  constructor(name: string) {
    this.name = name
  }

  /**
   * Convert the name to string, while removing ';' variable indicators.
   * @returns Parsed name as string.
   */
  toString() {
    return this.name.replace(/;/g, '')
  }

  /**
   * Get the name as it is saved in the metadata manager, including ';' variable indicators.
   * @returns Name as string.
   */
  getRaw() {
    return this.name
  }
}
