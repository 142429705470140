import { Empty } from 'antd'
import React from 'react'

import { CenteringDiv } from 'src/Modules/Utilities/Components/Centering/CenteringDiv'

/** Custom centered empty icon */
export default function CenteredEmpty() {
  return (
    <CenteringDiv>
      <Empty />
    </CenteringDiv>
  )
}
