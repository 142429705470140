import { GetDocumentNames } from 'src/Modules/Graphql/MetaDataManager/Queries'
import { CreateDataloader } from 'src/Modules/Home/Hooks/Dataloader'
import { CreateDataloadedQuery } from 'src/Modules/Home/Hooks/QueryClient'

const DocumentNameDataloader = CreateDataloader(
  GetDocumentNames,
  (documentName) => [documentName.id, documentName]
)

const { useSingle, useMultiple, fetchMultiple, invalidateMultiple } =
  CreateDataloadedQuery('documentName', DocumentNameDataloader)

export const useDocumentName = useSingle
export const useDocumentNames = useMultiple
export const fetchDocumentNames = fetchMultiple
export const invalidateDocumentNames = invalidateMultiple
