import DataLoader from 'dataloader'
import {
  QueryClient,
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions
} from 'react-query'

import { OneOrMany } from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/UseIds'

// Create client
export const queryClient = new QueryClient()

const queryOptionDefaults: CustomQueryOptions = {
  // default 5 minute staletime.
  staleTime: 1000 * 60 * 5,

  // default suspense is false
  suspense: false
}

type CustomQueryOptions = {
  staleTime?: number
  suspense?: boolean
  enabled?: boolean
}

/**
 * Run a query with default options.
 * @param queryKey The query key
 * @param queryFn The query function
 * @param options The query options
 * @returns The query result
 */
export function UseCustomQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery(queryKey, queryFn, {
    ...queryOptionDefaults,
    ...options
  })
}

/**
 * create functions to query data.
 * @param key the key to use for the query
 * @param dataLoader the data loader to use for the query
 * @returns an object containing multiple functions to retrieve and invalidate data.
 */
export function CreateDataloadedQuery<TResult>(
  key: string,
  dataLoader: DataLoader<number, TResult | null, number>
) {
  /**
   * Fetch a single item using a hook.
   * @param id The id of the item to fetch.
   * @param queryOptions The query options to use.
   * @returns The item.
   */
  function useSingle(id: number, queryOptions?: CustomQueryOptions) {
    return useQuery([key, id], async () => (await dataLoader.load(id))!, {
      ...queryOptionDefaults,
      ...queryOptions
    })
  }

  /**
   * Fetch multiple items using a hook.
   * @param ids The ids of the items to fetch.
   * @param queryOptions The query options to use.
   * @returns The items.
   */
  function useMultiple(ids: number[], queryOptions?: CustomQueryOptions) {
    return useQuery(
      [key, ids],
      () => Promise.all(ids.map(async (id) => (await dataLoader.load(id))!)),
      {
        ...queryOptionDefaults,
        ...queryOptions
      }
    )
  }

  /**
   * Fetch multiple items.
   * @param ids The ids of the items to fetch.
   * @returns The items.
   */
  function fetchMultiple(ids: number[]) {
    return queryClient.fetchQuery([key, ids], () =>
      Promise.all(ids.map(async (id) => await dataLoader.load(id))!)
    )
  }

  /**
   * Invalidate a multiple items.
   * @param ids The ids of the items to invalidate.
   * @returns A promise that resolves when the invalidation is complete.
   */
  function invalidateMultiple(ids: number[]) {
    return queryClient.invalidateQueries<[string, number[]]>({
      predicate: (query) =>
        (query.queryKey[0] as string) === key &&
        OneOrMany(query.queryKey[1] as number | number[]).some((queryId) =>
          ids.includes(queryId)
        )
    })
  }

  return {
    useSingle,
    useMultiple,
    fetchMultiple,
    invalidateMultiple
  }
}
