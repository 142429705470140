import styled from '@emotion/styled'
import { Button, Checkbox, Form, Input, Select, Table } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { parse } from 'csv-parse'
import jp from 'jsonpath'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { AddDocument } from 'src/Modules/Graphql/DocumentManager/Mutations'
import { ValidateDocument } from 'src/Modules/Graphql/DocumentManager/Queries'
import { SetDocumentKVP } from 'src/Modules/Graphql/MetaDataManager/Mutations'
import { GetSchemaValue } from 'src/Modules/Graphql/MetaDataManager/Queries'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import CustomDragger from 'src/Modules/Home/Components/Import/CustomDragger'
import { invalidateDocuments } from 'src/Modules/Home/Hooks/Document'
import { invalidateSchemas } from 'src/Modules/Home/Hooks/Schema'
import { GenerateAutoName, GetAutoName } from 'src/Modules/Utilities/AutoName'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

type row = {
  id: number
  selected: boolean
  value: any
}

type mapper = [string, string, string][]

/**
 * Csv importer
 * @param props
 * @returns A component.
 */
export function DocumentImporter(props: {
  submitText: string
  schemaId: number
}) {
  const [separator, setSeparator] = useState<string>(',')
  const [text, setText] = useState<string>()
  const [rows, setRows] = useState<row[]>()

  const navigate = useNavigate()
  const route = UseRoute()

  const { data: mapper } = useQuery(
    ['mapper', props.schemaId],
    async () => {
      const values = await GetSchemaValue(props.schemaId, 'mapper')
      if (!values) return null
      const map: [string, string, string][] = JSON.parse(values.value)
      return map
    },
    { onError: (e: any) => NotificationError('Error', e.message, e) }
  )

  const { data: autoName } = useQuery(
    ['autoName', props.schemaId],
    async () => (await GetAutoName(props.schemaId)) ?? null
  )

  // Parse the text to csv using the selected separator
  useEffect(() => {
    /** async useffect function */
    async function asyncUseEffect() {
      try {
        if (!text || !separator) return

        const newRows = await new Promise<any[]>((resolve, error) => {
          parse(
            text,
            { columns: true, ltrim: true, rtrim: true, delimiter: separator },
            (e, newRows: any[]) => {
              if (e) error(e)
              else resolve(newRows)
            }
          )
        })

        setRows((rows) =>
          newRows.map((row, index) => ({
            id: index,
            selected: rows?.find((row) => row.id === index)?.selected ?? true,
            value: row
          }))
        )
      } catch (e: any) {
        NotificationError('Invalid File', e.message, e)
        return
      }
    }
    asyncUseEffect()
  }, [text, separator])

  if (!mapper) return <CenteredCustomSpinner />

  const columns = [
    {
      title: 'Selected',
      key: 'Selected',
      render: (_: any, record: row) => (
        <Checkbox
          checked={record.selected}
          onChange={(e) =>
            // Update the row to be no longer selected
            setRows((rows) => {
              if (!rows) return
              const newRows = [...rows]
              const currentRow = newRows.find((row) => row.id === record.id)
              if (!currentRow) return
              currentRow.selected = e.target.checked
              return newRows
            })
          }
        />
      )
    },
    {
      title: 'Id',
      key: 'Id',
      render: (_: any, record: row) => record.id
    },
    {
      title: 'Name',
      key: 'Name',
      render: (_: any, record: any) =>
        autoName ? 'autoName' : record.value['DMName'] ?? 'Unnamed'
    },
    //Remove duplicate mapper keys and create columns
    ...[...new Set(mapper.map(([key, _]) => key))].map((key) => ({
      title: key,
      key: key,
      render: (_: any, record: row) => record.value[key]
    }))
  ]

  return (
    <TabContentWrapper
      bottom={
        <>
          <Button
            disabled={!rows}
            type='primary'
            onClick={async () => {
              await OnSubmit()
            }}
          >
            {props.submitText}
          </Button>
          <Button
            disabled={!rows}
            onClick={async () => {
              await OnValidate()
            }}
          >
            Validate
          </Button>
        </>
      }
    >
      <CustomDraggerBorder>
        <Form>
          <Form.Item>
            <CustomDragger
              beforeUpload={() => false} // Prevent antd from trying to upload the file (and fail)
              accept='.csv'
              multiple={false}
              maxCount={1}
              onChange={async (info) => await onChange(info)}
              showUploadList={false}
            />
          </Form.Item>
          <Form.Item label='Separator'>
            <Select
              onChange={(value) => setSeparator(value)}
              value={separator}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Input
                    placeholder='Custom'
                    onChange={(e) => setSeparator(e.target.value)}
                  />
                </div>
              )}
            >
              <Select.Option value=','>Comma</Select.Option>
              <Select.Option value=';'>Semicolon</Select.Option>
              <Select.Option value='	'>Tab</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </CustomDraggerBorder>
      <CustomDraggerBorder>
        <Table
          columns={columns}
          dataSource={rows}
          rowKey={(record) => record.id}
        />
      </CustomDraggerBorder>
    </TabContentWrapper>
  )

  /**
   * Validates the current selected rows.
   */
  async function OnValidate() {
    if (!rows || !mapper) return

    const selectedRows = rows.filter((row) => row.selected)
    const objects = RowsToObject(mapper, selectedRows)

    if (await ValidateObjects(props.schemaId, objects))
      NotificationSuccess('Validated', 'All rows successfully validated')
  }

  /** Function to call on submit  */
  async function OnSubmit() {
    if (!rows || !mapper) return

    const selectedRows = rows.filter((row) => row.selected)
    const objects = RowsToObject(mapper, selectedRows)

    if (!(await ValidateObjects(props.schemaId, objects))) return

    const doneRows: number[] = []
    const doneDocuments: number[] = []

    // Add all the objects
    for (const { id, columnName, object } of objects)
      try {
        const { _id } = await AddDocument(props.schemaId, object)
        doneRows.push(id)
        doneDocuments.push(_id)

        // If the document add was successful, try to add the name
        const name = autoName
          ? (await GenerateAutoName(autoName, object, [])).toString()
          : columnName
        if (name) await SetDocumentKVP(_id, 'name', name)
      } catch (e: any) {
        // If it fails, make sure the page is set again with the old values
        NotificationError(`Error on Add/Name Document ${id} `, e.message, e)
        setRows(
          rows.map((row) => ({
            ...row,
            // Set selected rows to not selected if they got done.
            selected: row.selected && !doneRows.some((d) => d === row.id)
          }))
        )
        return
      }

    onAdd(doneDocuments)

    navigate(`/${route}/schema/${props.schemaId}`)
  }

  /**
   * Updates the tree after adding documents
   * @param ids The added document ids
   */
  async function onAdd(ids: number[]) {
    await invalidateDocuments(ids)
    await invalidateSchemas([props.schemaId])
  }

  /**
   * Function to call on file change
   * @param info File info
   */
  async function onChange(info: UploadChangeParam<UploadFile<any>>) {
    const newText = await BlobToText(info.fileList[0].originFileObj)
    setText(newText)
  }

  /**
   * Convert a blob to a dataUrl
   * @param blob The blob to convert
   * @returns A dataUrl string
   */
  function BlobToText(blob?: Blob): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      // return undefined file input is undefined
      if (blob === undefined) resolve(undefined)
      else {
        const reader = new FileReader()
        reader.onload = (e) => {
          // The filereader needs a string, but this is not type checked, hence the typeof
          if (!e.target || typeof e.target.result !== 'string')
            reject('target empty or not string')
          else
            resolve(
              //Insert a name, to retrieve it in download.
              e.target.result
            )
        }
        reader.onerror = () => reject('error')
        reader.readAsText(blob)
      }
    })
  }
}

/**
 * Turns the selected rows into objects
 * @returns The objects
 */
function RowsToObject(mapper: mapper, rows: row[]) {
  // Turn the flat row into an object
  return rows.map((row) => {
    const object = {}
    mapper.forEach((mapping) => {
      const value = row.value[mapping[0]]
      const parsedValue = ParseValue(value, mapping?.[2])
      jp.value(object, mapping[1], parsedValue)
    })
    return { id: row.id, columnName: row.value['DMName'], object }
  })
}

/**
 * Validate objects.
 * @param schemaId the schema id to validate the objects with.
 * @param objects The objects to validate.
 * @returns Wether all objects passed validation.
 */
async function ValidateObjects(schemaId: number, objects: any[]) {
  const validationResults = await ValidateDocument(
    schemaId,
    objects.map(({ object }) => object)
  )

  for (let i = 0; i < validationResults.length; i++) {
    const validationResult = validationResults[i]
    if (validationResult.length === 0) continue
    NotificationError(
      `Error on validate Document ${objects[i].id}`,
      validationResult.reduce<string>((acc, val) => `${acc} ${val}`, '')
    )
    return false
  }

  return true
}

/**
 * Parse a value using the given type
 * @param value The value to parse
 * @param type The type to parse to
 * @returns The parsed value
 */
function ParseValue(value: string, type: string) {
  switch (type) {
    case 'number':
      return parseInt(value)
    case 'float':
      return parseFloat(value)
    case 'boolean': {
      const lowercase = value.toLowerCase()
      if (lowercase === 'true') return true
      if (lowercase === 'false') return false
      throw new Error(`Could not parse ${value} to boolean`)
    }
    //Treat as string by default
    default:
      return value
  }
}

// Styling
const CustomDraggerBorder = styled.div`
  padding: 10px;
`
