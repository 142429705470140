import { Client } from '@microsoft/microsoft-graph-client'
import 'isomorphic-fetch'

import GetAccessToken from 'src/Modules/Utilities/Authorization/GetAccessToken'

export const MSGraphClient = Client.initWithMiddleware({
  authProvider: {
    async getAccessToken() {
      return await GetAccessToken([
        'User.ReadBasic.All',
        'Group.Read.All',
        'Application.Read.All'
      ])
    }
  }
})
