import React, { ReactNode, Suspense } from 'react'

import CustomErrorBoundary from 'src/Modules/Common/Containers/CustomErrorBoundary'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'

/**
 * Suspense using a centered spinner.
 * @param children children elements.
 * @returns a suspense component with centered spinner.
 */
export function SuspenseAndBoundary({ children }: { children: ReactNode }) {
  return (
    <CustomErrorBoundary>
      <Suspense fallback={<CenteredCustomSpinner />}>{children}</Suspense>
    </CustomErrorBoundary>
  )
}
