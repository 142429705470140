import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'

import { RefreshButton } from 'src/Modules/Home/Components/NodeOptionButtons/RefreshButton'
import { AddDocumentButton } from 'src/Modules/Home/Components/Tree/TreeNodes/AddDocumentButton'
import { DocumentTreeNode } from 'src/Modules/Home/Components/Tree/TreeNodes/DocumentTreeNode'
import { MyTreeNode } from 'src/Modules/Home/Components/Tree/TreeNodes/MyTreeNode'
import { TreeProjection } from 'src/Modules/Home/Components/Tree/TreeWithSelect'
import { GetDocumentIdsWithJsonLogic } from 'src/Modules/Utilities/JsonLogic'

type CategoryTreeNodeProps = {
  name: string
  projections: TreeProjection[]
  parentDocumentId?: number
  defaultOpen?: boolean
  lastUpdated?: number
}

/** Tree node representing a schema */
export function CategoryTreeNode(props: CategoryTreeNodeProps) {
  const [filteredChildrenIds, setFilteredChildrenIds] =
    useState<{ id: number; projections?: TreeProjection[] }[]>()

  const buttonSchemaIds = props.projections
    .map((proj) => proj.addButtons ?? [])
    .flat()

  const GetChildren = useCallback(GetChildrenFunction, [
    filteredChildrenIds,
    buttonSchemaIds,
    props.parentDocumentId
  ])

  useEffect(() => {
    if (!props.name && !filteredChildrenIds) LoadChildrenIds()
  })

  const LoadChildrenIdsCallBack = useCallback(LoadChildrenIds, [
    props.parentDocumentId,
    props.projections
  ])

  useEffect(() => {
    LoadChildrenIdsCallBack()
  }, [props.lastUpdated, LoadChildrenIdsCallBack])

  // If a category name is set, then that means a category node should be rendered
  if (props.name)
    return (
      <MyTreeNode
        onOpen={() => OnOpen()}
        title={props.name}
        options={NodeOptions()}
        hasChildren={true}
        getChildren={GetChildren}
        defaultOpen={props.defaultOpen}
      />
    )
  // Otherwise, render the children directly
  return GetChildren()

  /**
   * Creates children components for this node
   * @returns The children components
   */
  function GetChildrenFunction() {
    if (!filteredChildrenIds) return null
    return (
      <div>
        {filteredChildrenIds.map((idWithProjection) => (
          <DocumentTreeNode
            documentId={idWithProjection.id}
            key={idWithProjection.id}
            projections={idWithProjection.projections}
          />
        ))}

        {buttonSchemaIds.map((schemaId) => {
          if (!schemaId) return null
          return (
            <AddDocumentButton
              key={schemaId}
              schemaId={schemaId}
              parentDocumentId={props.parentDocumentId}
            />
          )
        })}
      </div>
    )
  }

  /** Function to be called when the node is opened, loads the documents and schemas that fall under it */
  async function OnOpen() {
    LoadChildrenIds()
  }

  /** Get the ids of the children for this node based on the given json logic */
  async function LoadChildrenIds() {
    const idsWithProjections = (
      await Promise.all(
        props.projections.map(async (projection) =>
          (
            await GetDocumentIdsWithJsonLogic(
              projection.jsonLogic,
              props.parentDocumentId
            )
          ).map((id) => ({
            id,
            projections: projection.childNodes
          }))
        )
      )
    ).flat()

    setFilteredChildrenIds(idsWithProjections)
  }

  /** Create options for this tree node */
  function NodeOptions(): JSX.Element[] {
    return [
      <StyledRefreshButton
        key='Refresh'
        onClick={() => {
          if (filteredChildrenIds) LoadChildrenIds()
        }}
      />
    ]
  }
}

// Styling
const StyledRefreshButton = styled(RefreshButton)`
  margin-right: 5px;
`
