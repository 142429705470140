import React, { Component, ErrorInfo, ReactNode } from 'react'

import CenteredError from 'src/Modules/Common/Components/CenteredError'
import { NotFoundError } from 'src/Modules/Common/Logic/NotFoundError'

/**
 * Custom ErrorBoundary that only logs errors and continues to show page
 */
export default class CustomErrorBoundary extends Component<
  {
    children: ReactNode
  },
  { error?: { name: 'Uncaught' } | { name: 'Notfound'; id: number } }
> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = {}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public static getDerivedStateFromError(error: any) {
    if (error instanceof NotFoundError)
      return { error: { name: 'Notfound', id: error.id } }

    return { error: { name: 'Uncaught' } }
  }

  public render() {
    const error = this.state.error
    if (error === undefined) return this.props.children
    switch (error.name) {
      case 'Uncaught':
        return <CenteredError description='Uncaught error' />
      case 'Notfound':
        return (
          <CenteredError
            type='warning'
            description={`Item with id: ${error.id} not found.`}
            onClose={() => this.setState({ error: undefined })}
          />
        )
    }
  }
}
