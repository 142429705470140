import { Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { UseNavigateWithParam } from 'src/Modules/Common/Hooks/UseNavigateWithParam'
import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { NotFoundError } from 'src/Modules/Common/Logic/NotFoundError'
import { UpdateDocument } from 'src/Modules/Graphql/DocumentManager/Mutations'
import { SetDocumentKVP } from 'src/Modules/Graphql/MetaDataManager/Mutations'
import TopContentWrapper from 'src/Modules/Home/Components/ContentWrappers/TopContentWrapper'
import { RenameDocumentButton } from 'src/Modules/Home/Components/NodeOptionButtons/RenameButton/RenameDocumentButton'
import {
  TabPaneWrapper,
  TabsWrapper
} from 'src/Modules/Home/Components/TabsWrapper'
import { StyledFileOutlined } from 'src/Modules/Home/Components/Title/TitleIcons/StyledFileOutlined'
import { EditorTitle } from 'src/Modules/Home/Components/Title/TitleText/EditorTitle'
import {
  EviewConfiguration,
  EviewUpdate
} from 'src/Modules/Home/Containers/Content/Buttons/EviewHash'
import EviewSms from 'src/Modules/Home/Containers/Content/Buttons/EviewSms'
import {
  ActivateOliviaSimcard,
  DeactivateOliviaSimcard
} from 'src/Modules/Home/Containers/Content/Buttons/SimCardState'
import CoordinatePage from 'src/Modules/Home/Containers/Content/Tabs/CoordinatePage'
import { ExistingDocumentCodeEditor } from 'src/Modules/Home/Containers/Content/Tabs/Editors/DocumentCodeEditor'
import { ExportPage } from 'src/Modules/Home/Containers/Content/Tabs/Export/ExportPage'
import { KvpEditor } from 'src/Modules/Home/Containers/Content/Tabs/KvpEditor'
import MacAddressPage from 'src/Modules/Home/Containers/Content/Tabs/MacAddressPage'
import DocumentReferencesPage from 'src/Modules/Home/Containers/Content/Tabs/ReferencesPage/DocumentReferences'
import SimPage from 'src/Modules/Home/Containers/Content/Tabs/SimInformation'
import StatusPage from 'src/Modules/Home/Containers/Content/Tabs/StatusPage'
import useIntegrations from 'src/Modules/Home/Containers/Content/UseIntegrations'
import {
  invalidateDocuments,
  useDocument
} from 'src/Modules/Home/Hooks/Document'
import {
  invalidateDocumentNames,
  useDocumentName
} from 'src/Modules/Home/Hooks/DocumentName'
import { invalidateSchemas } from 'src/Modules/Home/Hooks/Schema'
import UseRole from 'src/Modules/Utilities/Authorization/UseRole'
import {
  AutoNameInputs,
  AutonameInputValues,
  GenerateAutoName,
  GetAutoName
} from 'src/Modules/Utilities/AutoName'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/** Container that contains a title and React Json Schema Form */
export function DocumentEditor(props: {
  documentId: number
  onSucceed: (documentId: number) => void
}) {
  const isDeveloper = UseRole('Developer')

  const navigate = UseNavigateWithParam()
  const route = UseRoute()

  const [autoNameInputs, setAutoNameInputs] = useState<string[]>()

  const { data: document } = useDocument(props.documentId, { suspense: true })

  const { data: documentName } = useDocumentName(props.documentId, {
    suspense: true
  })

  const { data: autoName } = useQuery(
    ['autoName', document?.schemaId],
    () => GetAutoName(document!.schemaId),
    { suspense: true, enabled: !!document }
  )
  const name = documentName?.name

  useEffect(() => {
    if (name) setAutoNameInputs(AutonameInputValues(name.getRaw()))
  }, [name])

  if (document === null) throw new NotFoundError(props.documentId)

  const Integrations = useIntegrations(document!.schemaId)

  return (
    <TopContentWrapper
      top={
        <>
          <div>
            <StyledFileOutlined />
            <Popover
              title={`Document Id: ${props.documentId}`}
              content={
                <div>
                  <p>
                    {document?.versionId
                      ? `Document Version Id: ${document.versionId}`
                      : 'Unknown Document Version'}
                  </p>
                  {document?.schemaId ? (
                    <Link to={`/development/schema/${document.schemaId}`}>
                      Schema Id: {document.schemaId}
                    </Link>
                  ) : (
                    <p>Unknown Schema</p>
                  )}
                </div>
              }
              trigger='hover'
            >
              <EditorTitle disabled={name === undefined}>
                {name?.toString() ?? 'no name'}
              </EditorTitle>
            </Popover>
            {autoName && (
              <AutoNameInputs
                autoName={autoName}
                inputs={autoNameInputs}
                onInputChange={setAutoNameInputs}
              />
            )}
            {autoName !== null ? (
              <></>
            ) : (
              <RenameDocumentButton
                key='Rename'
                documentId={props.documentId}
                name={name?.toString()}
              />
            )}
          </div>
          {Integrations?.hasEviewSms && (
            <EviewSms documentId={props.documentId} />
          )}
          {Integrations?.hasEviewForce && (
            <>
              <EviewUpdate documentId={props.documentId} />
              <EviewConfiguration documentId={props.documentId} />
            </>
          )}
          {Integrations?.hasOliviaSim && (
            <>
              <ActivateOliviaSimcard documentId={props.documentId} />
              <DeactivateOliviaSimcard documentId={props.documentId} />
            </>
          )}
        </>
      }
    >
      {Integrations && (
        <TabsWrapper>
          <TabPaneWrapper tab='Configuration' key='Configuration'>
            <ExistingDocumentCodeEditor
              documentId={props.documentId}
              onSubmit={(schema, formData) =>
                UpdateThisDocument(schema, formData)
              }
            />
          </TabPaneWrapper>
          <TabPaneWrapper tab='References' key='References'>
            <DocumentReferencesPage documentId={props.documentId} />
          </TabPaneWrapper>
          {Integrations.hasOliviaSim && (
            <TabPaneWrapper tab='Information' key='Information'>
              <SimPage documentId={props.documentId} />
            </TabPaneWrapper>
          )}
          {Integrations.hasLoggingStatus && (
            <TabPaneWrapper tab='Status' key='Status'>
              <StatusPage documentIds={[props.documentId]} />
            </TabPaneWrapper>
          )}
          {Integrations.hasLoggingCoordinates && (
            <TabPaneWrapper tab='Coordinates' key='Coordinates'>
              <CoordinatePage documentIds={props.documentId} />
            </TabPaneWrapper>
          )}
          {Integrations.hasLoggingMacAddresses && (
            <TabPaneWrapper tab='MacAddresses' key='MacAddresses'>
              <MacAddressPage documentIds={props.documentId} />
            </TabPaneWrapper>
          )}
          {Integrations.hasExport && (
            <TabPaneWrapper tab='Export' key='Export'>
              <ExportPage documentId={props.documentId} />
            </TabPaneWrapper>
          )}
          {isDeveloper && (
            <TabPaneWrapper tab='Metadata' key='Metadata'>
              <KvpEditor documentId={props.documentId} />
            </TabPaneWrapper>
          )}
        </TabsWrapper>
      )}
    </TopContentWrapper>
  )

  /**
   * Update the document in this editor on the document manager, then reload the page
   * @param formData The json of the document
   */
  async function UpdateThisDocument(_schema: any, formData: any) {
    // First update the document
    let newDocument
    try {
      newDocument = await UpdateDocument(props.documentId, formData)
    } catch (e: any) {
      // Notify the user on error
      NotificationError('Error on Update Document', e.message, e)
      return
    }

    await invalidateSchemas([
      ...(document?.referencingSchemas ?? []),
      ...newDocument.referencingSchemas.map((schema) => schema._id)
    ])
    await invalidateDocuments([
      props.documentId,
      ...(document?.referencingDocuments ?? []),
      ...newDocument.referencingDocuments.map((document) => document._id)
    ])

    let newName = name

    // If an auto name exists, it should be updated automatically
    if (autoName)
      try {
        newName = await GenerateAutoName(
          autoName as string,
          formData,
          autoNameInputs ?? []
        )

        await SetDocumentKVP(props.documentId, 'name', newName.getRaw())
        await invalidateDocumentNames([props.documentId])
      } catch (e: any) {
        // If only the name update fails, the rest can still continue, but the user should be notified
        NotificationError('Error when adding name', e.message, e)

        navigate(`/${route}/document/${props.documentId}`)

        return
      }

    NotificationSuccess('Success', 'Successfully updated document.')

    // On succesful update
    props.onSucceed(props.documentId)
  }
}
