import * as CSS from 'csstype'
import { JSONEditorOptions } from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
// @ts-expect-error next line different import as old one broken https://github.com/josdejong/jsoneditor/issues/1208
import JSONEditor from 'jsoneditor/dist/jsoneditor.js'
import React, { useEffect, useRef, useState } from 'react'

import { FullHeightDiv } from 'src/Modules/Common/Containers/FullHeightDiv'

type JsonEditorProps = {
  options: JSONEditorOptions
  json: any
  style?: CSS.Properties
  className?: string
  onChangeTextAsJson?: (json: any) => void
}

/** Wrapper for the JSONEditor, to integrate it properly into react */
export default function JsonEditor(props: JsonEditorProps) {
  const [editor, setEditor] = useState<JSONEditor>()
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current)
      if (!editor)
        setEditor(
          new JSONEditor(
            ref.current,
            {
              ...props.options,
              onChangeText: onChangeTextAsJson,
              theme: 'bootstrap4'
            },
            props.json
          )
        )
      else editor.update(props.json)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, props.json])
  return (
    <FullHeightDiv className={props.className} ref={ref} style={props.style} />
  )

  /**
   * Function to call so our custom callback gets called as well.
   * @param text The text to convert to a json object.
   */
  function onChangeTextAsJson(text: string) {
    props.options.onChangeText?.(text)
    try {
      props.onChangeTextAsJson?.(JSON.parse(text))
    } catch (error) {
      // Do nothing
    }
  }
}
