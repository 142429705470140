import { Alert } from 'antd'
import React from 'react'

import { UseNavigateWithParam } from 'src/Modules/Common/Hooks/UseNavigateWithParam'
import { CenteringDiv } from 'src/Modules/Utilities/Components/Centering/CenteringDiv'

/**
 * Centered message indicating unhandled error.
 * @returns A centered error message
 */
export default function NotFound() {
  const navigate = UseNavigateWithParam()

  return (
    <CenteringDiv>
      <Alert
        type='warning'
        message='Not found'
        description='This page could not be found, close this message to go to home.'
        closable
        onClose={() => navigate('/')}
      />
    </CenteringDiv>
  )
}
