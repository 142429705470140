import { CodeOutlined, FileTextOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Tooltip } from 'antd'
import React, { useState } from 'react'

import Form from 'src/Modules/Common/Components/Form'
import JsonEditor from 'src/Modules/Common/Components/JsonEditor'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'

type FormCodeEditorProps = {
  schema: any
  uiSchema?: any
  formData?: any
  submitText: string
  onSubmit: (formData: any) => Promise<void>
}

/**
 * Form and editor combined
 * @param props props
 * @returns A component
 */
export default function FormCodeEditor(props: FormCodeEditorProps) {
  const [viewState, setViewState] = useState<'form' | 'code'>('form')

  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const [formData, setFormData] = useState<any>(props.formData)

  return (
    <TabContentWrapper
      bottom={
        <>
          <Button
            disabled={hasErrors && viewState === 'form'}
            type='primary'
            onClick={() => props.onSubmit(formData)}
          >
            {props.submitText}
          </Button>
          <Tooltip
            placement='left'
            title={viewState === 'code' ? 'Go to form view' : 'Go to code view'}
          >
            <StyledToggle
              type='default'
              shape='round'
              icon={
                viewState === 'code' ? <FileTextOutlined /> : <CodeOutlined />
              }
              onClick={() =>
                setViewState((oldViewState) =>
                  oldViewState === 'code' ? 'form' : 'code'
                )
              }
            />
          </Tooltip>
        </>
      }
    >
      {viewState === 'code' ? (
        <JsonEditor
          options={{
            mode: 'view',
            modes: ['view', 'code', 'preview']
          }}
          onChangeTextAsJson={setFormData}
          json={formData}
        />
      ) : (
        <Form
          schema={props.schema}
          uiSchema={props.uiSchema}
          liveValidate={true}
          noHtml5Validate={true}
          formData={formData}
          onChange={(e) => {
            setHasErrors(e.errors.length > 0)
            setFormData(e.formData)
          }}
          showErrorList={false}
        />
      )}
    </TabContentWrapper>
  )
}

// Align the toggle button to the end of the bottom bar
export const StyledToggle = styled(Button)`
  align-self: center;
  grid-column-end: -1;
  margin-right: 5px;
`
