import { useMyAccount } from 'src/Modules/Utilities/Authorization/UseMyAccount'

type role = 'Developer'

/**
 * Check if the user has the given role
 * @param role The role to check
 * @returns Wether the user has the role
 */
export default function UseRole(role: role): boolean {
  const account = useMyAccount()
  return (account?.idTokenClaims as any)?.roles?.includes(role) ?? false
}
