import styled from '@emotion/styled'
import { Card } from 'antd'

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  justify-content: space-around;
  grid-auto-rows: auto;
  gap: 10px;
  margin: 10px;
  justify-items: stretch;
`

export const StyledCard = styled(Card)`
  min-width: 200px;
`
