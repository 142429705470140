import { useMsal } from '@azure/msal-react'
import { useMemo } from 'react'

import { TenantId } from 'src/Modules/Utilities/Environment'

/**
 * Returns the Account object if the user is signed-in
 */
export function useMyAccount() {
  const { accounts } = useMsal()

  const account = useMemo(
    () => accounts.find((account) => account.tenantId === TenantId),
    [accounts]
  )

  return account
}
