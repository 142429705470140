import styled from '@emotion/styled'
import React from 'react'

import { StyledLink } from 'src/Modules/Common/Components/LinkWithParam'
import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { useSchemaName } from 'src/Modules/Home/Hooks/SchemaName'
import { parentDocumentIdParam } from 'src/Modules/Utilities/Environment'

/**
 * add document button.
 * @param schemaId the id of the schema to add a document to
 * @param documentId The id of the document this add document got called from, in the tree
 * Used to get back the document from parameters and set it as a default value.
 * @returns an add document button.
 */
export function AddDocumentButton({
  schemaId,
  parentDocumentId
}: {
  schemaId: number
  parentDocumentId?: number
}) {
  const route = UseRoute()
  const { data: schemaName } = useSchemaName(schemaId)

  return (
    <MarginTextButton
      to={`/${route}/schema/${schemaId}/create`}
      search={
        parentDocumentId
          ? [[parentDocumentIdParam, parentDocumentId.toString()]]
          : undefined
      }
    >
      Add {schemaName?.value}
    </MarginTextButton>
  )
}

const MarginTextButton = styled(StyledLink)`
  margin-top: 4px;
`
