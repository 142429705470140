import {
  grey,
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  purple,
  magenta
} from '@ant-design/colors'

/**
 * Turn an id into a color
 * @param id The id to turn into a color
 * @returns A color
 */
function IdToColor(id: number) {
  const modulo = id % colors.length
  return colors[modulo]
}

/**
 * Class to generate colors based on id
 */
export class ColorGenerator {
  unusedColors: string[]
  usedColors: Map<number, string>

  constructor() {
    this.unusedColors = [...colors]
    this.usedColors = new Map()
  }

  /**
   * Return and store a random color
   * @param id The id to get the color from
   * @returns The color from the id
   */
  GetColor(id: number) {
    const usedColor = this.usedColors.get(id)
    if (usedColor) return usedColor
    const currentColor = this.unusedColors.pop() ?? IdToColor(id)
    this.usedColors.set(id, currentColor)
    return currentColor
  }
}

const colors = [
  grey[4],
  red[4],
  volcano[4],
  orange[4],
  gold[4],
  yellow[4],
  lime[4],
  green[4],
  cyan[4],
  blue[4],
  purple[4],
  magenta[4]
]
