import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import GetAccessToken from 'src/Modules/Utilities/Authorization/GetAccessToken'
import {
  DocumentManagerScope,
  EviewScope,
  LoggingDatabaseScope,
  MetadataManagerScope,
  OliviaScope
} from 'src/Modules/Utilities/Environment'

/**
 * Post a graphql message to a graphql server
 * @param address Adress of the server
 * @param query Message query
 * @param variables Message variables
 * @returns Document manager response
 */
async function fetchGraphQL(
  address: string,
  scopes: string[],
  query: string,
  variables: any
) {
  // Fetch data from GraphQL API:
  const response = await fetch(address, {
    method: 'POST',
    headers: {
      // Add authorization
      Authorization: `bearer ${await GetAccessToken(scopes)}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ query, variables })
  })
  // Get the response as JSON
  return await response.json()
}

export const LDRelayEnvironment = new Environment({
  network: Network.create((params: any, variables: any) =>
    fetchGraphQL(
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8011/proxy/graphql'
        : 'https://loggingdatabase.pdm.self.picasse.dev/graphql',
      LoggingDatabaseScope,
      params.text,
      variables
    )
  ),
  store: new Store(new RecordSource())
})

export const DMRelayEnvironment = new Environment({
  network: Network.create((params: any, variables: any) =>
    fetchGraphQL(
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8010/proxy/graphql'
        : 'https://documentmanager.pdm.self.picasse.dev/graphql',
      DocumentManagerScope,
      params.text,
      variables
    )
  ),
  store: new Store(new RecordSource())
})

export const MMRelayEnvironment = new Environment({
  network: Network.create((params: any, variables: any) =>
    fetchGraphQL(
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8012/proxy/graphql'
        : 'https://metadatamanager.pdm.self.picasse.dev/graphql',
      MetadataManagerScope,
      params.text,
      variables
    )
  ),
  store: new Store(new RecordSource())
})

export const EVRelayEnvironment = new Environment({
  network: Network.create((params: any, variables: any) =>
    fetchGraphQL(
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8013/proxy/graphql'
        : 'https://eviewwebapi.pdm.self.picasse.dev/graphql',
      EviewScope,
      params.text,
      variables
    )
  ),
  store: new Store(new RecordSource())
})

export const OliviaRelayEnvironment = new Environment({
  network: Network.create((params: any, variables: any) =>
    fetchGraphQL(
      'https://oliviasimcard.pdm.self.picasse.dev/graphql',
      OliviaScope,
      params.text,
      variables
    )
  ),
  store: new Store(new RecordSource())
})
